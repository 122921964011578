import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageGallerySliderComponent } from './image-gallery-slider.component';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { Lazy, Navigation, Pagination } from 'swiper';

SwiperCore.use([Lazy, Pagination, Navigation]);

@NgModule({
  declarations: [ImageGallerySliderComponent],
  imports: [CommonModule, SwiperModule],
  exports: [ImageGallerySliderComponent],
})
export class ImageGallerySliderModule {}
