export class ProjectUnitType {
  relationships: any = {};

  constructor(
    public id?: number,
    public project_id?: number,
    public published_available_count?: number,
    public unit_type?: string,
    public published_booked_count?: number,
    public sold_out_all_unit_flag?: boolean
  ) {}
}
