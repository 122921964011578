export class VideoMedia {
  public in_progress = false;
  relationships: any = {};

  constructor(
    public file?: any,
    public type?: string,
    public id?: string,
    public persisted?: boolean,
    public url?: string,
    public description?: string,
    public title?: string,
    public order_number?: number
  ) {}
}
