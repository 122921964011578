export class FamilyMember {
  relationships = {};

  constructor(
    public id?: number | string,
    public family_member_type: string = '',
    public national_id_number: string = '',
    public date_of_birth: string = '',
    public previously_received_housing_support: boolean = false,
    public lived_outside_of_ksa: boolean = false,
    public beneficiary_application_id: number = 0,
    public special_needs_or_chronic_diseases: boolean = false,
    public name: string = '',
    public values_from_api: number = 0,
    public not_eligible_values: number = 0,
    public has_deeds_or_building_licenses: boolean = false,
    public special_attention_values?: number,
    public redf_status: number = 0,
    public special_attention_keys?: []
  ) {}
}
