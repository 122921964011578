<div class="py-4" appLayoutDirection>
  <app-captcha
      class="mt-3"
      [action]="action"
      [identifier]="identifier"
      captchaType="grecaptchaV2"
      (captchaToken)="onChangeCaptchaToken($event)"
    ></app-captcha>
</div>

