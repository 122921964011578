export class Deed {
  relationships = {};

  constructor(
    public id?: string,
    public real_estate_type?: string,
    public size?: number,
    public deed_number?: number,
    public unit_number?: string,
    public plan_number?: string,
    public deed_hijri_date?: string,
    public beneficiary_application_id?: string,
    public family_member_id?: string,
    public created_at?: string,
    public updated_at?: string,
    public owning_percentage?: number,
    public not_eligible_values?: number,
    public values_from_api?: number,
    public special_attention_values?: number,
    public deed_type?: string,
    public construction_status?: string,
    public family_member_type?: string,
    public owner_name?: string,
    public city?: string,
    public source_type?: string,
    public region_id?: number,
    public city_id?: number,
    public preferred_communications_method?: any,
    public distance_borders?: { [key in string]: DeedBorderInfo },
    public unit_area?: number,
    public extra_info?: {
      housing_moj_service?: boolean;
    }
  ) {}
}

export class DeedBorderInfo {
  constructor(
    public border_type_string?: string,
    public border_description?: string,
    public border_distance?: string,
    public border_distance_string?: string
  ) {}
}
