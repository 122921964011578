import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-section',
  templateUrl: './error-section.component.html',
  styleUrls: ['./error-section.component.scss'],
})
export class ErrorSectionComponent implements OnInit {
  @Input() icon?: string | number = 'assets/icons/5xx.svg';
  @Input() code?: string;
  @Input() title?: string;
  @Input() desc?: string;

  constructor() {}

  ngOnInit(): void {}
}
