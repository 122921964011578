import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { finalize, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { currentLang } from '@app/helpers';

@Injectable({ providedIn: 'root' })
export class LokaliseService {
  LokaliseS3Url: string;
  translationLoaded = false;

  constructor(private api: ApiService) {
    const lokaliseS3Path: Record<string, string> = environment.lokaliseS3Path;
    this.LokaliseS3Url = lokaliseS3Path[currentLang()];
  }

  getLocales(): Observable<any> {
    const randomString = this.getCacheKey();
    return this.api.apiGet(this.LokaliseS3Url + '?cache=' + randomString, undefined, {}).pipe(
      finalize(() => (this.translationLoaded = true)),
      map((res) => {
        return res;
      })
    );
  }

  private getCacheKey(): number {
    const coeff = 1000 * 60 * environment.lokaliseCExpiredInMins;
    const date = new Date();
    return new Date(Math.round(date.getTime() / coeff) * coeff).getTime();
  }
}
