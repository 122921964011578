import { PriceQuotationStatus } from '@app/enums';

export class PriceQuotation {
  relationships: any = {};

  constructor(
    public id?: string,
    public bank_cr_number?: string,
    public bank_name?: string,
    public inquiry_expiry_date?: string,
    public issued_date?: string,
    public project_type?: string,
    public status?: PriceQuotationStatus,
    public acceptance_expiry_date?: string,
    public moh_price?: number,
    public property_year_of_built?: string,
    public inquired_date?: string,
    public unit_code?: string,
    public allow_auto_cancel?: boolean
  ) {}
}
