import { Component, HostBinding, TemplateRef } from '@angular/core';
import { ToastService } from '@app/base/services/ui/toast.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: 'toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
})
export class ToastContainerComponent {
  @HostBinding('class') toastClass = 'ngb-toasts';

  constructor(public toastService: ToastService) {
    this.toastService.clear();
  }

  isTemplate(toast: any): any {
    return toast.textOrTpl instanceof TemplateRef;
  }

  remove(toast: any): void {
    this.toastService.remove(toast);
  }
}
