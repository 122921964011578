export class ProjectWaitingListSetting {
  relationships: any = {};

  constructor(
    public id?: number,
    public apply_for_channels?: any,
    public confirmation_period?: string,
    public is_active?: boolean,
    public maximum_beneficiaries_per_project?: string,
    public maximum_projects_per_beneficiary?: string,
    public maximum_unit_types_per_project?: string,
    public product_type?: string
  ) {}
}
