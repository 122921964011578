import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';
import { CaptchaValidate, CaptchaService, CaptchaTypeEnum } from '@app/base/services/http/captcha.service';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { UntypedFormControl, Validators } from '@angular/forms';
import { EMPTY, of, timer } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Captcha } from '@app/models/captcha';
import { environment } from '@environment/environment';
import { RecaptchaComponent } from 'ng-recaptcha-2';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
})
export class CaptchaComponent implements OnInit {
  @ViewChild('captchaRef', { static: false }) recaptcha!: RecaptchaComponent;
  @Input() identifier: string = '';
  @Input() captchaType: string = CaptchaTypeEnum.grecaptchav3;
  @Input() action!: string;
  @Output() captchaToken: EventEmitter<string> = new EventEmitter<string>();
  captchaImage: Captcha | null = null;
  captchaChallengeForm: UntypedFormControl = new UntypedFormControl('', {
    validators: [Validators.required, Validators.minLength(4)],
  });
  oldCaptchaChallenge = '';
  showErrors = false;
  recaptchaV2SiteKey = environment.recaptchaSiteKey;

  constructor(private captchaService: CaptchaService) {}

  get isInvalid(): boolean {
    return this.captchaChallengeForm.invalid && (this.captchaChallengeForm.dirty || this.captchaChallengeForm.touched);
  }

  ngOnInit(): void {
    if (this.captchaType == 'sakani_captcha') {
      this.basicCapthcaInit();
    } else if (this.captchaType == CaptchaTypeEnum.grecaptchav3) {
      // if captchaType = v3 then do nothing but wait for it fail, when v3 failed, v2 will show as next challenge
      this.captchaService.showV3Badge();
      this.listenToV3Fallback();
    }
  }

  listenToV3Fallback() {
    this.captchaService.v3ValidatedFailed$.pipe(untilDestroyed(this)).subscribe((flag) => {
      if (flag) {
        this.captchaType = CaptchaTypeEnum.grecaptchav2;
      }
    });
  }

  basicCapthcaInit() {
    this.getCaptchaImage();
    this.captchaService.refresh$.subscribe(() => {
      this.getCaptchaImage();
    });
    this.captchaChallengeForm.valueChanges
      .pipe(
        filter(
          () =>
            this.captchaChallengeForm.valid &&
            this.oldCaptchaChallenge !== this.captchaChallengeForm.value &&
            !!this.captchaImage?.id
        ),
        debounceTime(800),
        switchMap((res) => {
          const paramsValidateRecaptcha: CaptchaValidate = {
            recaptcha_type: CaptchaTypeEnum.sknRecaptcha,
            recaptcha_token: this.captchaImage?.captcha_token || '',
            recaptcha_challenge: res,
            identifier: this.captchaImage?.id || '',
          };

          return this.captchaService.validateCaptcha(paramsValidateRecaptcha);
        }),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        if (value) {
          this.oldCaptchaChallenge = this.captchaChallengeForm.value;
          this.showErrors = false;
          this.captchaToken.emit(value);
        }
      });
  }

  grecaptchaResolved(token: string | null) {
    if (!token) {
      return;
    }
    this.showErrors = false;
    const paramsValidateRecaptcha: CaptchaValidate = {
      recaptcha_type: this.captchaType as CaptchaTypeEnum,
      recaptcha_token: '',
      recaptcha_challenge: token,
      identifier: this.identifier,
      action: this.action,
      site_key: environment.recaptchaSiteKey,
    };
    this.captchaService.validateGoogleRecaptcha(paramsValidateRecaptcha).subscribe({
      next: (data) => {
        this.captchaToken.emit(data);
      },
      error: (e) => {
        if (this.captchaType == CaptchaTypeEnum.grecaptchav2) {
          this.showErrors = true;
          this.recaptcha.reset();
        }
      },
    });
  }

  getCaptchaImage(): void {
    this.captchaChallengeForm.reset('');
    this.captchaImage = null;
    this.captchaChallengeForm.disable();

    this.captchaService
      .getCaptchaToken(150, 60)
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.captchaImage = res;
        this.captchaChallengeForm.enable();
      });
  }
}
