import { Injectable } from '@angular/core';
import { ViewCountInterface } from '@app/interfaces/tracking.interface';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ApiService } from '../http/api.service';
import { Endpoints } from '@app/helpers/endpoints';
import { catchError, of } from 'rxjs';
import { SakaniSessionService } from '../ui/sakani-session.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityTrackingService {
  ACTIVITY_TRACKING_EVENT = 'activity_tracking';
  GOOGLE_ANALYTIC_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

  constructor(
    private apiService: ApiService,
    private fireAnalytics: AngularFireAnalytics,
    private sakaniSessionService: SakaniSessionService
  ) {}

  viewed({ id, type, action, booking_session_id = null }: ViewCountInterface) {
    this.apiService
      .apiPost(Endpoints.ACTIVITY_COLLECT_PATH, {
        id,
        type,
        act: action,
        booking_session_id,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          return of();
        })
      )
      .subscribe();
  }

  gaLogEvent(eventName: string, eventParams?: { [key: string]: any }, options?: any): Promise<any> {
    const currentUserId = this.sakaniSessionService.currentUser?.user_id || undefined;
    const params = { ...eventParams, user_id: currentUserId };
    return this.fireAnalytics.logEvent(eventName, params, options);
  }

  gaPageView(pageTitle?: string, queryParams: any = {}): Promise<any> {
    const pageLocation = window.location.origin;
    let queryParamsPath = window.location.search || '';
    let campaignParamsPath = '';
    let campaignPrefix = '';
    const gaParams = JSON.parse(sessionStorage.getItem('ga_url_params') || '{}');
    if (Object.keys(gaParams).length > 0) {
      campaignParamsPath = Object.keys(gaParams)
        .map((key) => `utm_${key}=${gaParams[key]}`)
        .join('&');

      if (queryParamsPath === '') {
        campaignParamsPath = '?' + campaignParamsPath;
      } else {
        campaignParamsPath = '&' + campaignParamsPath;
      }

      if (gaParams.campaign) {
        campaignPrefix = `GA_campaign__${gaParams.campaign}`;
      }
    }

    let pagePath = campaignPrefix + window.location.pathname + '/' + queryParamsPath;

    if (!pagePath.startsWith('/')) {
      pagePath = '/' + pagePath;
    }

    return this.fireAnalytics.logEvent('page_view', {
      page_location: pageLocation,
      page_path: pagePath,
      page_title: campaignPrefix ? campaignPrefix : pageTitle,
    });
  }

  setAnalyticUserId(userId: string) {
    this.fireAnalytics.setUserId(userId);
  }

  setAnalyticUserProps(params: any) {
    this.fireAnalytics.setUserProperties(params);
  }

  setAnalyticParamsToProps(params: any = {}) {
    const gaObj: any = {};
    this.GOOGLE_ANALYTIC_PARAMS.forEach((item: string) => {
      if (params[item]) {
        const finestkey = item.replace('utm_', '');
        gaObj[finestkey] = params[item];
      }
    });

    if (Object.keys(gaObj).length > 0) {
      sessionStorage.setItem('ga_url_params', JSON.stringify(gaObj));
    }

    const gaParams = JSON.parse(sessionStorage.getItem('ga_url_params') || '{}');
    if (Object.keys(gaObj).length >= 0) {
      this.setAnalyticUserProps(gaParams);
    }
  }
}
