export enum AppealStatus {
  NEW = 'new',
  PENDING = 'pending',
  UNDER_PROCESSING = 'under_processing',
  DOCUMENT_REQUIRED = 'document_required',
  COMMITTEE_DESCISION = 'committee_decision',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum AppealState {
  FAIL = 'fail',
  SUCCESS = 'success',
}

export enum AppealStepStatus {
  COMPLETED = 'completed',
  UNCOMPLETED = 'uncompleted',
  PROCESSING = 'processing',
}

export enum AppealStepState {
  NEW = 'new',
  VISITED = 'visited',
}
