export enum DesignOrderType {
  ExteriorDesigns = 'exterior',
  InteriorDesigns = 'interior',
  ExteriorAndInteriorDesigns = 'exterior_and_interior',
}

export enum DesignOrderStatus {
  Initialized = 'initialized',
  Created = 'created',
  InProgress = 'in_progress',
  Pending = 'pending',
  Completed = 'completed',
  Expired = 'expired',
  Cancelled = 'cancelled',
}

export enum DesignOrderPurchaseType {
  NonFitting = 'non_fitting',
  Fitting = 'fitting',
  FittingAndBuildingPermit = 'fitting_and_building_permit',
}
