import { City } from './city';
import { Region } from './region';
export class QueryMegaProject {
  relationships = {};

  constructor(
    public id?: string,
    public content?: string,
    public cover_image?: string,
    public location?: { lat?: number; lon?: number },
    public min_price?: number,
    public project_codes: string[] = [],
    public title?: string,
    public city?: City,
    public region?: Region
  ) {}
}
