import { MarketRequestStatus } from '@app/enums/market-request-status.enum';
import { GroupUnit } from './group-unit';
import { MarketUnit } from './market-unit';

type ModelType = 'unit_marketing_requests' | 'requests';
type RequestType = 'group_unit' | 'unit' | 'land';
export class MarketRequest {
  relationships = {
    market_units: { rel: 'has_many', model: MarketUnit },
    group_unit: { rel: 'has_one', model: GroupUnit },
  };
  constructor(
    public id?: string,
    public requester_name?: string,
    public region_id?: number,
    public city_id?: number,
    public request_type?: RequestType,
    public request_status?: string,
    public request_code?: string,
    public broker_company_name?: string,
    public request_date?: string,
    public requester_id?: string,
    public requester_type?: string,
    public owner_id?: string,
    public editable?: boolean,
    public market_units?: MarketUnit[],
    public group_unit?: GroupUnit,
    public company_cr_number?: string,
    public owner_name?: string,
    public delegation_request_id?: number,
    public type?: ModelType
  ) {}

  get market_unit(): MarketUnit | null {
    return this.market_units ? this.market_units[0] : null;
  }

  get communication_by(): 'mobile_number' | 'whatsapp' | null {
    if (this.market_unit?.preferred_communication_type?.mobile_number === true) return 'mobile_number';
    if (this.market_unit?.preferred_communication_type?.whatsapp === true) return 'whatsapp';
    return null;
  }

  get isUnderUpdate(): boolean {
    return (
      this.request_status === MarketRequestStatus.UNDER_UPDATE_BY_OWNER ||
      this.request_status === MarketRequestStatus.UNDER_UPDATE_BY_BROKER
    );
  }

  get deedNumbers(): Array<string | number> {
    if (!this.market_units) {
      return [];
    }
    return this.market_units
      ?.map((marketUnit) => (marketUnit?.deed_number ? marketUnit.deed_number : ''))
      .filter((deed) => deed);
  }

  get isUnitMarketingRequest() {
    return this.type === 'unit_marketing_requests';
  }
}
