import { AppealStatus } from '@app/enums/appeal.enum';
import { BeneficiaryApplicationStatus, EligibilityStatus } from '@app/enums';
import { AdditionalData, Deed, FamilyMember } from '.';
import { Appeal } from './appeal';
import { CrmRequiredDocument } from './crm-required-document';
import { RuleResult } from './rule-result';
import { SupportingDocument } from './supporting-documents';
import { unknownBeneficiaryApplicationStatus } from '@app/helpers';

export class BeneficiaryApplication {
  relationships: any = {
    additional_data: { rel: 'has_one', model: AdditionalData },
    deeds: { rel: 'has_many', model: Deed },
    family_members: { rel: 'has_many', model: FamilyMember },
  };
  family_members?: FamilyMember[];
  additional_data?: AdditionalData;
  deeds?: Deed[];

  constructor(
    public id?: string,
    public beneficiary_national_id_number?: string,
    public employment_level?: number,
    public applicant_lived_outside_ksa_additional_information?: string,
    public dependent_support?: boolean,
    public dependent_free_land?: boolean,
    public have_special_needs?: boolean,
    public chronic_diseases?: boolean,
    public family_category?: string,
    public education_level?: number,
    public status?: BeneficiaryApplicationStatus,
    public external_validation_errors?: string,
    public created_at?: string,
    public updated_at?: string,
    public supporting_documents?: SupportingDocument[],
    public residency_type?: string,
    public residency_unit_type?: string,
    public values_from_api?: number,
    public eligible_status?: EligibilityStatus,
    public not_eligible_values?: number,
    public redf_status?: number,
    public has_grant?: boolean,
    public marital_status?: string,
    public citizen_life_status?: string,
    public indigent_value?: number,
    public special_attention_values?: number,
    public currently_live_in_habitable_home?: boolean,
    public currently_live_in_habitable_home_info?: string,
    public living_situation?: string,
    public date_of_birth?: string,
    public family_assets_exceed?: boolean,
    public owned_a_housing_deed?: boolean,
    public applicant_not_lived_outside_ksa?: boolean,
    public rule_results?: RuleResult[],
    public appeals?: Appeal[],
    public special_attention_keys?: string[],
    public api_data_quality_issues?: number,
    public eligibility_questions_answered?: boolean,
    public crm_missing_documents?: CrmRequiredDocument[],
    public family_size?: number,
    public application_member_size?: number,
    public sex?: string,
    public beneficiary_id_issuance_date?: string,
    public beneficiary_id_issuance_place?: string,
    public social_status?: string,
    public api_issues?: any,
    public api_error_fetch_count?: number,
    public approved_at?: Date,
    public rejected_at?: Date,
    public waived_at?: Date,
    public waived_eligibility_file?: string,
    public source_type?: number,
    public updated_by?: string,
    public pending_reasons?: string[],
    public is_inherited?: boolean,
    public inherited_eligibility_id?: string,
    public last_appeal?: Appeal,
    public decrypt_beneficiary_national_id_number?: string,
    public exist_in_moho_list?: boolean,
    public decrypt_inherited_eligibility_id?: string,
    public spouse_waived_name?: string
  ) {}

  get hasNeverApplyEligibleBefore() {
    return (
      this.status === BeneficiaryApplicationStatus.STATUS_INITIAL &&
      this.eligible_status === EligibilityStatus.NOT_ELIGIBLE
    );
  }

  get isUnknownStatus() {
    if (this.status) {
      if (this.eligible_status === EligibilityStatus.NOT_ELIGIBLE) {
        return unknownBeneficiaryApplicationStatus.includes(this.status);
      }

      if (this.eligible_status === EligibilityStatus.ELIGIBLE) {
        return this.status === BeneficiaryApplicationStatus.STATUS_PROCESSING;
      }

      return false;
    }

    return true;
  }

  get canRaiseAppeal(): boolean {
    if (!this.rule_results) {
      return false;
    }
    if (
      this.status === BeneficiaryApplicationStatus.STATUS_PROCESSING ||
      this.status === BeneficiaryApplicationStatus.STATUS_WAIVED
    ) {
      return false;
    }
    // note: use != here to handle both null and undefined
    const hasNewRule = this.rule_results.find((item) => item.sub_appeal == null) != null;
    const hasOwnedHouse = this.living_situation === 'owned_house';
    let canResubmit = true;
    if (this.appeals && this.appeals.length > 0) {
      canResubmit = this.appeals[this.appeals.length - 1].canResubmit;
    }
    return hasNewRule && !this.hasInvalidRule && !hasOwnedHouse && canResubmit;
  }

  get hasInvalidRule(): boolean {
    return this.invalidRulesAppeal?.length! > 0;
  }

  get invalidRulesAppeal(): RuleResult[] | undefined {
    // get invalid rules that the user doesn't have permission to submit appeal
    const rules = this.rule_results?.filter((rule: any) => {
      const invalidRuleDetails =
        rule.rule_key === 'family_category' &&
        ['dependent_applied', 'husband_applied', 'beneficiary_age'].includes(rule.details || '');

      const invalidRuleKey = [
        'waived_application',
        'uncompleted_application',
        'currently_live_in_habitable_home',
      ].includes(rule.rule_key);

      if (invalidRuleDetails || invalidRuleKey) {
        return rule;
      }
    });
    return rules;
  }

  get appealRejectReasons(): RuleResult[] {
    if (!this.rule_results) {
      return [];
    }
    return this.rule_results.map((rule: RuleResult) => {
      const ruleText = rule.details
        ? 'ELIGIBILITY.DETAILED_RULE_RESULTS.' + rule.details.toUpperCase()
        : 'ELIGIBILITY.INELIGIBLE_RULES.' + rule.rule_key.toUpperCase();
      return {
        ...rule,
        rule_text: ruleText,
      };
    });
  }

  get isEligible() {
    return this.eligible_status === EligibilityStatus.ELIGIBLE;
  }

  get isIneligible() {
    return this.eligible_status === EligibilityStatus.NOT_ELIGIBLE;
  }

  get isProcessing() {
    return this.status === BeneficiaryApplicationStatus.STATUS_PROCESSING;
  }

  get isPending() {
    return this.status === BeneficiaryApplicationStatus.STATUS_PENDING;
  }

  get isCompleted() {
    return this.status === BeneficiaryApplicationStatus.STATUS_COMPLETED;
  }

  get isInitial() {
    return this.status === BeneficiaryApplicationStatus.STATUS_INITIAL;
  }

  get isWaved() {
    return this.status === BeneficiaryApplicationStatus.STATUS_WAIVED;
  }

  get hasError() {
    return this.status === BeneficiaryApplicationStatus.STATUS_ERROR;
  }

  get noHaveOpenAppeal() {
    if (this.last_appeal) {
      // user has last-appeal and its related status
      return [AppealStatus.APPROVED, AppealStatus.REJECTED].includes(this.last_appeal?.appeal_status!);
    } else {
      // user doesn't have last appeal
      return true;
    }
  }

  get hasMissingCrmDocs() {
    return Array.isArray(this.crm_missing_documents) && this.crm_missing_documents.length > 0;
  }
}
