export enum BlockingReason {
  HAS_ACTIVE_BOOKING = 'already_has_active_booking',
  NOT_ELIGIBLE = 'is_not_eligible',
  HAS_ACTIVE_CONTRACT = 'already_has_active_contract',
  HAS_NO_ELIGIBILITY_APPLICATION = 'has_no_eligibility_application',
  EMPTY = '',
  IS_ALREADY_ALLOCATED = 'is_already_allocated',
  HAS_FINANCIAL_APPLICATION = 'has_financial_application',
  EXIST_IN_MOHO_LIST = 'exist_in_moho_list',
  NOT_APPLIED_FINANCIAL_ADVISORY = 'not_applied_financial_advisory',
  NO_BENEFICIARY_APPLICATION = 'no_beneficiary_application',
  EXCEED_FINANCIAL_RECOMMENDATION_PERIOD = 'exceed_financial_recommendation_period',
  HAS_OPEN_APPEAL = 'has_open_appeal',
  HAS_NO_RECOMMENDATION = 'has_no_recommendation',
  HAS_NO_AVAILABLE_UNITS = 'has_no_available_units',
  PENDING_FOR_INELIGIBLE = 'pending_for_ineligible',
  NOT_DEFINED_ELIGIBLE = 'not_defined_eligible',
  ELIGIBLE_HAS_ACTIVE_BOOKING = 'eligible_has_active_booking',
  NON_BENE_HAS_UNPAID_BOOKING_FEE = 'non_bene_has_unpaid_booking_fee',
  EXCEED_MAXIMUM_BOOKING_PER_NON_BENE_BY_PROJECT = 'exceed_maximum_booking_per_non_bene_by_project',
  MULTIPLE_BOOKING_FOR_NON_BENE_DISABLED = 'multiple_booking_for_non_bene_disabled',
}
