import { TranslateService } from '@ngx-translate/core';
import { Constants } from './constants';

export interface Locale {
  lang: string;
  data: any;
}

export const currentLang = () => {
  return (
    localStorage.getItem(Constants.LOCAL_STORAGE_LANGUAGE) ||
    Constants.DEFAULT_LANGUAGE
  );
};

/*
 * Change the current language
 * @param translate An instance of the TranslateService
 * @param lang The new language code
 */
export const changeLanguage = (translate: TranslateService, lang: string) => {
  const userLang = navigator.language;

  translate.use(lang || userLang);
  localStorage.setItem(Constants.LOCAL_STORAGE_LANGUAGE, lang || userLang);
};

export const getTranslatedText = async (
  translate: TranslateService,
  key: string
) => {
  const text = (await translate.get(key).toPromise()) as string;

  return text;
};

export const getTranslatedTexts = async (
  translate: TranslateService,
  ...keys: string[]
) => {
  const translatedTexts = await translate.get(keys).toPromise();

  return keys.map((key) => translatedTexts[key] || key);
};

export const loadTranslations = (
  translate: TranslateService,
  ...args: Locale[]
): void => {
  const locales = [...args];
  locales.forEach((locale) => {
    translate.setTranslation(locale.lang, locale.data, true);
  });
};
