import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioComponent } from './radio.component';
import { LabelModule } from '../label/label.module';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutDirectionModule } from '@app/shared/directives';

@NgModule({
  declarations: [RadioComponent],
  imports: [
    CommonModule,
    LabelModule,
    FormsModule,
    TranslateModule,
    LayoutDirectionModule,
  ],
  exports: [RadioComponent],
})
export class RadioModule {}
