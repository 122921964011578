import { Injectable } from '@angular/core';
import { Endpoints } from '@app/helpers/endpoints';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { QueryPollingService } from './query-polling.service';

@Injectable({
  providedIn: 'root',
})
export class BookingSessionService {
  bookingSessionsUrl!: string;
  constructor(private api: ApiService, private pollingService: QueryPollingService) {
    this.bookingSessionsUrl = Endpoints.MAIN_INTERMEDIARY_V4 + '/booking_sessions';
  }

  leaveQueue(): Observable<any> {
    return this.api.apiPost(`${this.bookingSessionsUrl}/leave_queue`, {});
  }
}
