export class ImageMedia {
  relationships: any = {};
  public in_progress = false;

  constructor(
    public file?: any,
    public type?: string,
    public id?: string,
    public persisted?: boolean,
    public url?: string,
    public caption?: string,
    public order_number?: number,
    public thumbnail_url?: string
  ) {}
}
