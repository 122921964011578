import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecaptchaV2DialogComponent } from './recaptcha-v2-dialog.component';
import { CaptchaModule } from '@app/shared/components';

@NgModule({
  declarations: [RecaptchaV2DialogComponent],
  imports: [CommonModule, CaptchaModule],
  exports: [RecaptchaV2DialogComponent],
})
export class RecaptchaV2DialogModule {}
