import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { currentDirection } from '@app/helpers';

@Directive({
  selector: '[appLayoutDirection]',
})
export class LayoutDirectionDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    const dir = currentDirection();
    this.renderer.setAttribute(this.el.nativeElement, 'dir', dir);
    document.documentElement.classList.add(dir);
  }
}
