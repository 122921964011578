import { Endpoints } from '@app/helpers/endpoints';

export class FinancialRecommendation {
  public relationships = {};

  constructor(
    public id?: number,
    public lender_cr_number?: string,
    public lender_name?: string,
    public cr_number?: string,
    public loan_tenure?: number,
    public max_loan_amount?: number,
    public period_full_dbr?: number,
    public period_full_dbr_payment?: number,
    public period_full_retiree_dbr?: number,
    public period_full_retiree_dbr_payment?: number,
    public period_reduced_dbr?: number,
    public period_reduced_dbr_payment?: number,
    public period_reduced_retiree_dbr_payment?: number,
    public period_reduced_retiree_dbr?: number,
    public property_value?: number,
    public recommendation_date?: number,
    public subsidy_monthly_amount?: number,
    public subsidy_total_amount?: number,
    public total_payment?: number,
    public transfer_of_personal_finance?: boolean,
    public supported_financing?: boolean,
    public advance_payment_support?: boolean,
    public guarantees?: boolean,
    public downpayment?: number,
    public created_at?: Date,
    public updated_at?: Date
  ) {}

  get bankLogo() {
    return `${Endpoints.MAIN_INTERMEDIARY_V3}/financial_institutions/${this.lender_cr_number}/logo`;
  }
}
