import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { EsriMapViewModule } from '../esri-map-view/esri-map-view.module';
import { SakaniEsriMapComponent } from './sakani-esri-map.component';

@NgModule({
  declarations: [SakaniEsriMapComponent],
  imports: [CommonModule, NgbModalModule, EsriMapViewModule],
  exports: [SakaniEsriMapComponent],
})
export class SakaniEsriMapModule {}
