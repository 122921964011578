export enum UnitLegendsDisplay {
  ONLINE = 'online',
  OFFLINE = 'offline',
  ONLINE_APP = 'online_app',
  ONLINE_WEB = 'online_web',
  AUCTION_OPEN = 'open',
  AUCTION_EXPIRED = 'expired',
  AUCTION_CLOSED = 'closed',
  AUCTION_NOT_STARTED = 'not_started',
}
