export class City {
  relationships = {};
  constructor(
    public id?: number,
    public name?: string, // city name by selected language.
    public name_ar?: string,
    public name_en?: string,
    public lat?: number,
    public lon?: number,
    public region_id?: number,
    public city_id?: number
  ) {}
}
