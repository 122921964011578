import { ImageThumbGalleryDataInterface } from '@app/shared/interfaces';
import { City } from './city';
import { Region } from './region';

class UploadObject {
  public upload_key: string = '';
  public url: string = '';
  public caption?: string;
}

export class MegaProject {
  relationships: any = {};
  constructor(
    public id?: string,
    public title?: string,
    public content?: string,
    public longitude?: number,
    public latitude?: number,
    public video_link?: string,
    public banner_image?: UploadObject,
    public image_galleries?: UploadObject[],
    public brochure?: UploadObject,
    public total_area?: number,
    public apartment_count?: number,
    public villa_townhouse_count?: number,
    public suburban_facilities_count?: number,
    public suburban_facilities_area?: number,
    public commercial_facilities_count?: number,
    public commercial_facilities_area?: number,
    public garden_open_space_count?: number,
    public garden_open_space_area?: number,
    public security_count?: number,
    public security_area?: number,
    public healthcare_count?: number,
    public healthcare_area?: number,
    public schools_count?: number,
    public schools_area?: number,
    public kindergarten_area?: number,
    public kindergarten_count?: number,
    public masjid_count?: number,
    public masjid_area?: number,
    public project_codes?: string[],
    public city_id?: number,
    public region_id?: number,
    public status?: number,
    public caption?: string,
    public created_at?: string,
    public city_obj?: City,
    public region_obj?: Region
  ) {}

  get imageVideoThumbsGallery(): ImageThumbGalleryDataInterface[] {
    const videoThumb: ImageThumbGalleryDataInterface = {
      sourceUrl: this.video_link!,
      thumbUrl: this.video_link!,
      originUrl: this.video_link!,
      type: 'video',
    };

    const rawGallery = this.image_galleries || [];

    const gallery = rawGallery?.map((g: any) => {
      const data: ImageThumbGalleryDataInterface = {
        sourceUrl: g.url,
        thumbUrl: g.url,
        originUrl: g.url,
        caption: g?.caption,
      };
      return data;
    });

    let projectImages = [];
    if (videoThumb.sourceUrl) {
      projectImages.push(videoThumb);
    }

    if (gallery.length > 1) {
      projectImages.push(...gallery);
    }

    if (projectImages.length === 0) {
      projectImages.push(videoThumb);
    }

    return projectImages;
  }
}
