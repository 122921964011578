import { BuildingPermitStatus } from '@app/enums';

export class BuildingPermit {
  relationships: any = {};

  constructor(
    public id?: number,
    public booking_id?: number,
    public file?: any,
    public status?: BuildingPermitStatus,
    public reject_reason?: string,
    public building_permit_id?: string,
    public issuance_date?: Date,
    public reviewed_at?: Date,
    public created_at?: Date,
    public file_type?: string,
    public updated_at?: Date
  ) {}
}
