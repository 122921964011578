import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegionCityName } from './region-city-name.pipe';

@NgModule({
  declarations: [RegionCityName],
  imports: [CommonModule],
  exports: [RegionCityName],
})
export class RegionCityNameModule {}
