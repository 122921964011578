export class Captcha {
  relationships = {};

  constructor(
    public id?: string,
    public img_data?: string,
    public height?: number,
    public width?: number,
    public captcha_token?: string
  ) {}
}
