import { Injectable } from '@angular/core';
import { RecaptchaV2DialogComponent } from '@app/shared/dialogs/recaptcha-v2-dialog/recaptcha-v2-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class CaptchaDialogService {
  constructor(private modalService: NgbModal) {}

  showCaptchaDialog(action?: any, identifier?: any) {
    const modalRef = this.modalService.open(RecaptchaV2DialogComponent, { centered: true });
    modalRef.componentInstance.action = action;
    modalRef.componentInstance.identifier = identifier;
    modalRef.result.then((result: any) => {
      if (result) {
        // console.log(result);
        // refresh page after the captcha is solved, user was removed from blacklist
        window.location.reload();
      } else {
      }
    });
  }
}
