<div class="page-error bg-light">
  <div class="d-flex flex-column justify-content-center py-15 px-6">
    <div class="d-flex flex-column align-items-center gap-4 text-center">
      <div class="d-flex flex-row align-items-between gap-3">
        <img [src]="icon" alt="" />
        <div class="display-1 fw-bold">{{ code }}</div>
      </div>
      <h1>{{ title ? title : ('SERVER_ERROR.UNAVAILABLE_SERVICE_TITLE' | translate) }}</h1>
      <h4>{{ desc ? desc : ('SERVER_ERROR.UNAVAILABLE_SERVICE_DESC' | translate) }}</h4>
    </div>
  </div>
</div>
