import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IServerError } from '@app/interfaces/server-error.interface';
import { BehaviorSubject } from 'rxjs';

export interface LoadingEventData {
  status: boolean;
  text?: string;
  textWrapperClass?: string; // the class wrapper outside of text
  textClass?: string; // the class for text itself
}

@Injectable({ providedIn: 'root' })
export class LoadingEventService {
  private defaultParam: Pick<LoadingEventData, 'text' | 'textClass' | 'textWrapperClass'> = {
    text: '',
    textWrapperClass: 'mt-2',
    textClass: 'text-warning',
  };

  private loadingEvent = new BehaviorSubject<LoadingEventData>({
    status: false,
    ...this.defaultParam,
  });

  private serverErrorEvent = new BehaviorSubject<IServerError | null>(null);

  getLoadingEvent = this.loadingEvent.asObservable();
  getServerErrorEvent = this.serverErrorEvent.asObservable();

  constructor() {}

  setState(isLoading: boolean | LoadingEventData): void {
    setTimeout(() => {
      if (typeof isLoading === 'boolean') {
        this.loadingEvent.next({
          status: isLoading,
          text: '',
        });
      } else {
        this.loadingEvent.next(isLoading);
      }
    }, 0);
  }

  start(param?: Pick<LoadingEventData, 'text' | 'textClass' | 'textWrapperClass'>) {
    this.setState({
      status: true,
      ...param,
    });
  }

  complete() {
    this.setState({
      status: false,
      ...this.defaultParam,
    });
  }

  handleErrorCode(error: any) {
    if (error instanceof HttpErrorResponse == false) {
      return;
    }
    if (error.status >= 500) {
      this.serverError({
        code: (error as HttpErrorResponse).status.toString(),
      });
    } else if (error.status == 404) {
      window.location.href = '/page-not-found';
    } else if (error.status == 403) {
      this.serverError({
        code: (error as HttpErrorResponse).status.toString(),
      });
    }
  }

  serverError(error: IServerError) {
    this.setState(false);
    this.serverErrorEvent.next(error);
  }

  getCurrentState(): LoadingEventData {
    return this.loadingEvent.value;
  }
}
