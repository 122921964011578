import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsListPipe } from './errors-list.pipe';

@NgModule({
  declarations: [ErrorsListPipe],
  imports: [CommonModule],
  exports: [ErrorsListPipe],
})
export class ErrorsListModule {}
