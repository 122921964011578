import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import {
  LayoutDirectionModule,
  InputCurrencyDirective,
  InputSarCurrencyDirective,
  InputMonthDirective,
} from './directives';
import { SakaniMultipleUploadModule } from 'sakani-ng-uploader/dist/sakani-upload-files';
import { NgxMaskDirective } from 'ngx-mask';

const sharedModules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgbModule,
  TranslateModule,
  NgSelectModule,
  LayoutDirectionModule,
  SakaniMultipleUploadModule,
  NgxMaskDirective,
];

const directives = [InputCurrencyDirective, InputSarCurrencyDirective, InputMonthDirective];

const externalModules = [TranslateModule];
@NgModule({
  declarations: [...directives],
  imports: [...sharedModules, ...externalModules],
  exports: [...sharedModules, ...directives],
})
export class SharedModule {}
