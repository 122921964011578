export enum AuctionStatus {
  NOT_STARTED = 'not_started',
  OPEN = 'open',
  CLOSED = 'closed',
  EXPIRED = 'expired',
  NOT_TO_BID = 'not_to_bid',
  WAITING_FOR_CONTRACT = 'waiting_for_contract',
  WAITING_FOR_PAYMENT = 'waiting_for_payment',
  SOLD = 'sold',
}

export enum AvailablePlotsFor {
  BOOKING = 1,
  AUCTION = 2,
}

export enum AuctionPaymentStatuses {
  INITIAL = 'initial',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
}
