import { Injectable } from '@angular/core';
import { ApiService } from '@app/base/services/http/api.service';
import { Observable, Subject, from, of, timer } from 'rxjs';
import { Endpoints } from '@app/helpers/endpoints';
import { Captcha } from '@app/models/captcha';
import { catchError, map, switchMap } from 'rxjs/operators';
import { createPayload } from '@app/helpers';
import { JsonApi } from '@app/models';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaV2DialogComponent } from '@app/shared/dialogs/recaptcha-v2-dialog/recaptcha-v2-dialog.component';
import { environment } from '@environment/environment';

export interface CaptchaValidate {
  identifier?: string;
  action?: string;
  recaptcha_token?: string;
  recaptcha_challenge: string;
  recaptcha_type: CaptchaTypeEnum;
  site_key?: string;
}

export enum CaptchaTypeEnum {
  sknRecaptcha = 'sakani_captcha',
  grecaptchav2 = 'grecaptchaV2',
  grecaptchav3 = 'grecaptchaV3',
}

@Injectable({
  providedIn: 'root',
})
export class CaptchaService {
  private captchaUrl = Endpoints.CAPTCHA_URL;
  private authUrl = Endpoints.AUTH_API_URL;
  public refresh$: Subject<any> = new Subject();
  public v3ValidatedFailed$: Subject<any> = new Subject();

  constructor(private api: ApiService, private recaptchaV3Service: ReCaptchaV3Service, private modalService: NgbModal) {
    this.showV3Badge();
  }

  public getCaptchaToken(imageWidth: number, imageHeight: number): Observable<Captcha> {
    return this.api.apiPost(`${this.captchaUrl}/generate`).pipe(
      map((res: any) => {
        return JsonApi.parseJsonApi(Captcha, res.data);
      })
    );
  }

  public validateCaptcha(captcha: CaptchaValidate): Observable<string> {
    const payload = createPayload(captcha);
    return this.api
      .apiPost(`${this.authUrl}/recaptcha/validate`, payload)
      .pipe(map((res: any) => res.data?.attributes?.recaptcha_auth_token));
  }

  public validateGoogleRecaptcha(captcha: CaptchaValidate): Observable<string> {
    const payload = createPayload(captcha);
    return this.api
      .apiPost(`${this.captchaUrl}/grecaptcha/validate`, payload)
      .pipe(map((res: any) => res.data?.attributes?.recaptcha_auth_token));
  }

  standaloneRecaptchaValidate(action: string, identifier: string, defaultFailHandle = true): Observable<string | null> {
    return this.executeV3Verification(action).pipe(
      switchMap((gToken) => {
        this.hideV3Badge();
        return this.validateGoogleRecaptcha({
          action: action,
          identifier: identifier,
          recaptcha_challenge: gToken,
          recaptcha_type: CaptchaTypeEnum.grecaptchav3,
          site_key: environment.recaptchaV3SiteKey,
        });
      }),
      catchError((err) => {
        console.error(err);
        this.hideV3Badge();
        if (defaultFailHandle) {
          const modalRef = this.modalService.open(RecaptchaV2DialogComponent, { centered: true });
          modalRef.componentInstance.action = action;
          modalRef.componentInstance.identifier = identifier;
          return from(modalRef.result);
        } else {
          this.v3ValidatedFailed$.next(true);
          return of(null);
        }
      })
    );
  }

  refresh() {
    this.refresh$.next(Date.now());
  }

  hideV3Badge() {
    timer(3000).subscribe(() => {
      document.body.classList.remove('recaptcha');
    });
  }

  showV3Badge() {
    document.body.classList.add('recaptcha');
  }

  executeV3Verification(action: string): Observable<any> {
    return this.recaptchaV3Service.execute(action);
  }
}
