export class FinancialAdvisory {
  public relationships = {};

  constructor(
    public id?: number,
    public beneficiary_national_id_number?: string,
    public num_of_family?: number,
    public area_code?: number,
    public city_code?: number,
    public type_work?: number,
    public name_military_sector?: string,
    public rank_militry?: string,
    public salary?: number,
    public housing_allowance?: number,
    public total_salary?: number,
    public monthly_obligations?: number,
    public type_product?: string,
    public num_months_remaining?: number,
    public bank_cr_number?: string,
    public ready_get_loan?: boolean,
    public contact_type?: string,
    public contact_info?: string,
    public is_have_land?: boolean,
    public is_have_building_permit?: boolean,
    public is_start_building?: boolean,
    public unit_availability?: boolean,
    public downpayment?: boolean,
    public downpayment_amount?: number,
    public project_code?: string,
    public nearest_city?: string,
    public product_preference_code?: string,
    public is_old_update?: boolean,
    public has_new_recommendation?: boolean,
    public age_hijri?: number,
    public age_miladi?: number,
    public additional_income?: number,
    public other_allowance?: number,
    public district_you_want_to_buy_it?: string,
    public submitted_at?: Date,
    public recommendation_response_code?: string,
    public created_at?: Date,
    public updated_at?: Date
  ) {}
}
