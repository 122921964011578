import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutDirectionDirective } from './layout-direction.directive';

@NgModule({
  declarations: [LayoutDirectionDirective],
  imports: [CommonModule],
  exports: [LayoutDirectionDirective],
})
export class LayoutDirectionModule {}
