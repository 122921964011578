export class BeneficiaryAssetDetail {
  relationships: any = {};

  constructor(
    public beneficiary_national_id_number?: string,
    public number_of_dependents?: string,
    public job_code?: string,
    public property_type?: string,
    public job_employment_date?: Date,
    public property_region?: string,
    public property_city?: string,
    public property_price?: string,
    public loan_amount_to_borrow?: number,
    public loan_repayment_in_years?: number,
    public monthly_salary?: number,
    public home_allowance?: number,
    public transport_allowance?: number,
    public monthly_deduction?: number,
    public job_employment_date_original?: string,
    public type_of_work?: string,
    public monthly_salary_api?: number,
    public monthly_salary_financial_advisory?: number,
    public home_allowance_api?: number,
    public home_allowance_financial_advisory?: number,
    public additional_income?: number,
    public additional_income_api?: string,
    public bank_name_financial_advisory?: string,
    public bank_id?: number,
    public job_title?: string,
    public job_name?: string,
    public job_location?: string,
    public name_military_sector?: string,
    public rank_militry?: string,
    public monthly_obligations_financial_advisory?: string,
    public num_months_remaining_financial_advisory?: string,
    public monthly_obligations?: string,
    public num_months_remaining?: string,
    public plan_to_own_house_in_months?: number,
    public plan_to_own_house_submitted_at?: Date,
    public financial_advisory_input?: any,
    public region_id?: string,
    public city_id?: string,
    public preferred_region_id?: string,
    public preferred_city_id?: string,
    public updated_at?: Date,
    public package_amount?: number,
    public packages?: any,
    public monthly_subsidy_amount?: number,
    public product_type?: number
  ) {}
}
