import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerMessageComponent } from './banner-message.component';
import { ErrorsListModule, SafeModule } from '@app/shared/pipes';
import { RadioModule } from '../radio/radio.module';
import { CollapseBoxModule } from '../collapse-box/collapse-box.module';
import { SharedModule } from '@app/shared/shared.module';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CountdownModule } from '@app/shared/components/countdown/countdown.module';
import { WarningBannerMessageModule } from '../warning-banner-message/warning-banner-message.module';

@NgModule({
  declarations: [BannerMessageComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    ErrorsListModule,
    RadioModule,
    CollapseBoxModule,
    SafeModule,
    NgbCollapseModule,
    CountdownModule,
    WarningBannerMessageModule,
  ],
  exports: [BannerMessageComponent],
})
export class BannerMessageModule {}
