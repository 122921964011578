export type LngLatLike = [number, number] | { lng: number; lat: number } | { lon: number; lat: number };

import { AuctionStatus, UnitType } from '@app/enums';

export class MapboxLayers {
  // When get properties we must use 'get' expression. It won't break multiple conditions
  public static readonly ONLINE_LAYER = {
    name: 'layer-online',
    color: 'rgba(0, 172, 131, 1)',
    borderColor: '#ffffff',
    filter: [
      'all',
      ['any', ['in', 'web', ['get', 'sales_channels']], ['in', 'mobile', ['get', 'sales_channels']]],
      ['!=', ['get', 'sold_out'], true],
      ['==', ['get', 'auction_status'], AuctionStatus.NOT_TO_BID],
    ],
  };

  // When get properties we must use 'get' expression. It won't break multiple conditions
  public static readonly OFFLINE_LAYER = {
    name: 'layer-offline',
    color: 'rgba(229, 192, 58, 1)',
    borderColor: '#ffffff',
    filter: [
      'all',
      ['in', 'partners', ['get', 'sales_channels']],
      ['!=', ['get', 'sold_out'], true],
      ['==', ['get', 'auction_status'], AuctionStatus.NOT_TO_BID],
    ],
  };

  // sold out layer for apartment
  public static readonly SOLD_OUT_LAYER = {
    name: 'layer-sold-out',
    color: 'rgba(208, 92, 92, 1)',
    borderColor: '#ffffff',
    filter: ['all', ['==', 'sold_out', true], ['==', 'unit_type', UnitType.APARTMENT]],
  };

  // sold out layer for apartment
  public static readonly BOOKED_LAYER = {
    name: 'layer-booked',
    color: 'rgba(208, 92, 92, 1)',
    borderColor: '#ffffff',
    filter: ['all', ['==', 'booked', true]],
  };

  // inactive, Charity, Tanmawi, Non-Beneficiary unit layer gray
  public static readonly GRAY_LAYER = {
    name: 'layer-sold-out',
    color: 'rgba(138, 151, 155, 1)',
    borderColor: '#ffffff',
    filter: ['all', ['==', 'inactive', true]],
  };

  // for auction layers
  public static readonly LAND_AUCTION_NOT_STARTED_LAYER = {
    name: 'layer-land-auction-not-started',
    color: '#06222B',
    pattern: 'warning',
    borderColor: '#ffffff',
    filter: ['all', ['==', 'auction_status', AuctionStatus.NOT_STARTED]],
  };

  public static readonly LAND_AUCTION_OPEN_LAYER = {
    name: 'layer-land-auction-open',
    color: '#59759B',
    pattern: 'primary',
    borderColor: '#ffffff',
    filter: ['all', ['==', 'auction_status', AuctionStatus.OPEN]],
  };

  public static readonly LAND_AUCTION_CLOSED_EXPIRED_LAYER = {
    name: 'layer-land-auction-closed-expired',
    color: '#8A979B',
    pattern: 'danger',
    borderColor: '#ffffff',
    filter: ['all', ['in', 'auction_status', AuctionStatus.CLOSED, AuctionStatus.EXPIRED]],
  };

  public static readonly LEGEND_COLORS = [
    { pattern: 'primary', url: 'assets/images/legend-color-primary.png' },
    { pattern: 'warning', url: 'assets/images/legend-color-warning.png' },
    { pattern: 'danger', url: 'assets/images/legend-color-danger.png' },
  ];
}
