import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

// TODO: refactor this service, not using old one
@Injectable({ providedIn: 'root' })
export class SakaniSessionStorageService {
  private nameSpace = 'sakani-storage';
  private globalVersionNumber = 1;
  storageContainer: any;
  private storageName = 'sakani-storage/sessionstorage/';
  constructor(private localStorageService: LocalStorageService) {
    this.storageContainer = this.localStorageService.retrieve(
      this.formatStorageName(this.storageName, this.globalVersionNumber)
    );
  }

  private formatStorageName(storageName: string, globalVersion: number) {
    return [storageName, globalVersion].join('/');
  }

  private saveStorage() {
    this.localStorageService.store(
      this.formatStorageName(this.storageName, this.globalVersionNumber),
      this.storageContainer
    );

    return this.storageContainer;
  }

  getStorage(): any {
    return this.storageContainer;
  }

  setStorage(dollyDustStorage: any) {
    this.storageContainer = dollyDustStorage;
    this.saveStorage();
  }

  updateStorage(key: string, value: any) {
    this.storageContainer[key] = value;
    this.saveStorage();
  }

  clearStorage() {
    return this.setStorage(null);
  }
}
