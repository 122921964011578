export enum MarketplaceSplitviewSortEnum {
  'MOST_POPULAR' = '-views_count',
  'PRICE_HIGH_TO_LOW' = '-marketplace_price',
  'PRICE_LOW_TO_HIGH' = 'marketplace_price',
  'NEWEST_FIRST' = '-publish_date',
  'OLDEST_FIRST' = 'publish_date',
  'RECOMMENDED' = 'recommended',
  'PROJECT_FIRST' = 'project_first',
  'MARKET_UNIT_FIRST' = 'market_unit_first',
}
