import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SafeModule } from '@app/shared/pipes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InformationTableModule } from '../information-table/information-table.module';
import { CollapseBoxComponent } from './collapse-box.component';

@NgModule({
  declarations: [CollapseBoxComponent],
  imports: [CommonModule, TranslateModule, NgbModule, SafeModule, InformationTableModule, RouterModule],
  exports: [CollapseBoxComponent],
})
export class CollapseBoxModule {}
