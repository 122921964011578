export class TextualMedia {
  public in_progress = false;
  public type = 'textual';
  relationships: any = {};

  constructor(
    public title?: string,
    public name?: string,
    public description?: string,
    public summary?: string,
    public id?: string,
    public price_starting_at?: number,
    public promoted?: boolean,
    public latitude?: number,
    public longitude?: number,
    public order_number?: number,
    public sale_agent_name?: string,
    public sale_agent_email?: string,
    public sale_agent_phone_number?: string,
    public first_unit_delivery_date?: string,
    public title_of_detail_page?: string
  ) {}

  get coordinates(): any {
    let coordinates = null;

    if (this.latitude && this.longitude) {
      coordinates = {
        latitude: parseFloat('' + this.latitude),
        longitude: parseFloat('' + this.longitude),
      };
    }

    return coordinates;
  }
}
