import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ProjectCardComponent } from './project-card.component';
import { BadgeModule } from '../badge/badge.module';
import { ImageGallerySliderModule } from '../image-gallery-slider/image-gallery-slider.module';
import { TranslateModule } from '@ngx-translate/core';
import { MinMaxRangeModule, RegionCityNameModule } from '@app/shared/pipes';
import { LayoutDirectionModule } from '@app/shared/directives';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [ProjectCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    ImageGallerySliderModule,
    TranslateModule,
    BadgeModule,
    RegionCityNameModule,
    MinMaxRangeModule,
    LayoutDirectionModule,
    SharedModule,
  ],
  exports: [ProjectCardComponent],
})
export class ProjectCardModule {}
