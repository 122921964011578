export enum EsriMapPolygonStatus {
  STATUS = 'status',
  ONLINE = 'online',
  ONLINE_WEB = 'online-web',
  ONLINE_APP = 'online-app',
  OFFLINE = 'offline',
  NONE = 'none',
  SOLD_OUT = 'sold_out',
  BOOKED = 'booked',
  INACTIVE = 'inactive',
  LAND_AUCTION_NOT_STARTED = 'land_auction_not_started',
  LAND_AUCTION_OPEN = 'land_auction_open',
  LAND_AUCTION_CLOSED_EXPIRED = 'land_auction_closed_expired',
}

export enum EsriMapGeometryType {
  POLYGON = 'polygon',
  POLYLINE = 'polyline',
  POINT = 'point',
}

export enum EsriMapUtils {
  OPEN_STREET_MAP = 'osm',
  HYBRID_MAP = 'hybrid',
  TITLE_LAYER_EN_ID = 'efa46686e17d44d48dded3b31c62c83e',
  TITLE_LAYER_AR_ID = 'a281aec6cb134074aa0371653bac7e7c',
  OPEN_STREET_MAP_EN_ID = 'ab924202cc404af58b41efcf99b0aafa',
  OPEN_STREET_MAP_AR_ID = '2b2ece2b339244608d69be5500cc53f5',
  HYBRID_STREET_MAP_ID = '10df2279f9684e4a9f6a7f08febac2a9',
}
