import { Injectable } from '@angular/core';
import { SakaniSession } from '@app/models';
import { BookingSession } from '@app/models/booking-session';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, map, of, Subject, switchMap, takeUntil, timer } from 'rxjs';
import { BookingSessionService } from '../http/booking-session.service';
import { ProjectService } from '../http/project.service';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { SakaniModalService } from '@app/base/services/ui/sakani-modal.service';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class BookingTokenService {
  currentBookingInfo?: BookingSession;
  public session: SakaniSession | undefined;
  public bookingTokenSubs: BehaviorSubject<any> = new BehaviorSubject(null);
  public queueingTokenSubs?: Subject<any>;

  constructor(
    private projectService: ProjectService,
    private bookingSessionService: BookingSessionService,
    private sakaniModalService: SakaniModalService
  ) {
    this.loadBookingSession();
  }

  setSession(bookingSession: BookingSession) {
    if (bookingSession == null && this.isExpired()) {
      return;
    }
    if (bookingSession == null) {
      this.clear();
      return;
    }
    localStorage.setItem('bookingSession', JSON.stringify(bookingSession));

    this.loadBookingSession();
  }

  pollingProjectQueue() {
    if (this.queueingTokenSubs && !this.queueingTokenSubs.closed) {
      return this.queueingTokenSubs;
    }

    if (!this.currentProjectId() || this.hasToken()) {
      return;
    }

    this.queueingTokenSubs = new Subject<any>();
    timer(0, 5000)
      .pipe(
        switchMap(() => {
          if (this.currentProjectId()) {
            return this.projectService.getQueueing(this.currentProjectId()!.toString());
          } else {
            return of(undefined);
          }
        }),
        takeUntil(this.queueingTokenSubs)
      )
      .subscribe((data) => {
        if (data) {
          const currentQueuePos = data.list_of_queue_tokens.findIndex((i: string) => {
            return i == this.currentBookingInfo!.queueing_token;
          });
          let maxActiveMember = this.currentBookingInfo?.max_active_queueing_member!;
          const totalNumberInProjectQueue = data?.total_number_in_queue || 0;

          if (maxActiveMember !== data.max_active_queueing_member!) {
            maxActiveMember = data.max_active_queueing_member!;
          }

          if (currentQueuePos === -1) {
            this.currentBookingInfo!.currentQueuePosition = 101;
          } else {
            const alreadyJoinedBookingUsers = data.already_joined_booking_count!;
            this.currentBookingInfo!.currentQueuePosition =
              currentQueuePos + 1 - Math.max(alreadyJoinedBookingUsers, maxActiveMember);
          }

          this.currentBookingInfo!.allow_joined_queue_to_start_booking = data.allow_joined_queue_to_start_booking;
          this.currentBookingInfo!.availableUnitsCount = data?.available_units_count;
          this.currentBookingInfo!.selectedUnitsCount = +data?.selected_units_count;
          this.currentBookingInfo!.publishedAvailableUnits = data?.published_available_units!;
          this.currentBookingInfo!.max_active_queueing_member = data.max_active_queueing_member!;
          this.currentBookingInfo!.already_joined_booking_count = data.already_joined_booking_count!;

          if (maxActiveMember >= totalNumberInProjectQueue) {
            this.currentBookingInfo!.totalNumberInQueue = 0;
          } else {
            this.currentBookingInfo!.totalNumberInQueue = totalNumberInProjectQueue - maxActiveMember;
          }

          this.bookingTokenSubs.next(this.currentBookingInfo);
        }

        if (
          !data ||
          !this.currentProjectId() ||
          this.hasToken() ||
          (data?.available_units_count <= 0 && data?.selected_units_count == 0)
        ) {
          this.queueingTokenSubs!.next(null);
          this.queueingTokenSubs!.unsubscribe();
        }
      });

    return this.queueingTokenSubs;
  }

  setReservedUnit(unit: any) {
    if (!this.currentBookingInfo) {
      return;
    }
    this.currentBookingInfo.unit_id = unit.id;
    localStorage.setItem('bookingSession', JSON.stringify(this.currentBookingInfo));
  }

  currentProjectName(): string {
    return this.currentBookingInfo?.project_name!;
  }

  currentProjectId(): number {
    return this.currentBookingInfo?.project_id!;
  }

  currentUnitId(): number {
    return this.currentBookingInfo?.unit_id!;
  }

  expiredAt(): number {
    return this.currentBookingInfo?.expired_at!;
  }

  isExpired(): boolean {
    if (!this.currentBookingInfo) {
      return false;
    }

    if (!this.currentBookingInfo.expired_at) {
      return false;
    }
    const expiredAt = this.currentBookingInfo?.expired_at || 0;
    return Date.now() > expiredAt * 1000;
  }

  hasToken(): boolean {
    return this.currentBookingInfo?.booking_token && this.currentBookingInfo?.booking_token != '' ? true : false;
  }

  hasQueueingToken(): boolean {
    return this.currentBookingInfo?.queueing_token ? true : false;
  }

  activeFinancingGuaranteeJourney(): boolean {
    return this.currentBookingInfo?.active_financing_guarantee_journey ? true : false;
  }

  fetchFRCRetryTimes(): number {
    return this.currentBookingInfo?.fetch_frc_retry_times || 0;
  }

  hasBookingUnit(): boolean {
    return !this.currentBookingInfo?.unit_id ? false : true;
  }

  leaveQueue() {
    return this.bookingSessionService.leaveQueue().pipe(
      map((res: any) => {
        this.clear();
        return res;
      })
    );
  }

  clear() {
    if (this.currentBookingInfo) {
      localStorage.removeItem('bookingSession');
      this.currentBookingInfo = undefined;
      this.bookingTokenSubs.next(undefined);
    }
  }

  clearTrackingPage() {
    localStorage.removeItem('loadPage');
  }

  matchProject(projectId: number): boolean {
    return this.currentProjectId() === projectId;
  }

  public loadBookingSession(): void {
    const bookingSession: string | null = localStorage.getItem('bookingSession');

    try {
      if (bookingSession && bookingSession !== 'undefined') {
        this.currentBookingInfo = JSON.parse(bookingSession);
      } else {
        this.currentBookingInfo = undefined;
      }
    } catch {
      this.currentBookingInfo = undefined;
    }

    if (
      this.currentBookingInfo &&
      this.currentBookingInfo.queueing_token &&
      this.currentBookingInfo.expired_at == null
    ) {
      this.pollingProjectQueue();
    }

    this.bookingTokenSubs.next(this.currentBookingInfo);
  }
}
