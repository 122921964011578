export enum BookingStatus {
  INITIAL = 'initial',
  PRICE_QUOTATION = 'price_quotation',
  FINAL = 'final',
  ALLOCATED = 'allocated',
  CANCELLED = 'cancelled',
  SELECTED = 'selected',
  CONFIRMED = 'confirmed',
  BUILDING_PERMIT_PHASE = 'building_permit_phase',
  CONSTRUCTION_PHASE = 'construction_phase',
  COMPLETED = 'completed',
  CONTRACTED = 'contracted',
  SOLD = 'sold',
  BOOKED = 'booked',
  CONFIRMED_BY_DEVELOPER = 'confirmed_by_developer',
}
