export class ActiveSubsidyContract {
  relationships = {};

  constructor(
    public id?: string,
    public status?: string,
    public subsidy_contract_number?: number,
    public signed_at?: Date,
    public generated_at?: Date,
    public created_at?: Date,
    public cancelled_at?: Date
  ) {}
}
