import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaptchaComponent } from './captcha.component';
import { CheckboxModule } from '@app/shared/components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorsListModule } from '@app/shared/pipes';
import { LayoutDirectionModule } from '@app/shared/directives';
import { RecaptchaModule } from 'ng-recaptcha-2';

@NgModule({
  declarations: [CaptchaComponent],
  imports: [
    CommonModule,
    CheckboxModule,
    RecaptchaModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorsListModule,
    TranslateModule,
    LayoutDirectionModule,
  ],
  exports: [CaptchaComponent],
})
export class CaptchaModule {}
