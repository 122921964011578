import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { SakaniSessionService } from '@app/base/services/ui/sakani-session.service';

@Injectable()
export class AuthGuard {
  constructor(private router: Router, private sessionService: SakaniSessionService) {}

  canActivate(route: ActivatedRouteSnapshot, routeState: RouterStateSnapshot) {
    if (this.sessionService.isAuthenticated) {
      return true;
    } else {
      this.router.navigate(['/authentication/login'], {
        queryParams: { returnUrl: routeState.url },
      });
      return false;
    }
  }
}
