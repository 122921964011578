import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationTableComponent } from './information-table.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [InformationTableComponent],
  imports: [CommonModule, TranslateModule],
  exports: [InformationTableComponent],
})
export class InformationTableModule {}
