export class AdditionalData {
  relationships = {};

  constructor(
    public id?: string,
    public beneficiary_application_id?: string,
    public nin_father?: string,
    public nin_mother?: string,
    public dob_father?: string,
    public dob_mother?: string,
    public parents_alive?: boolean,
    public not_eligible_values?: number,
    public values_from_api?: number,
    public special_attention_values?: number,
    public guardian_nin?: string,
    public guardian_name?: string,
    public father_is_deceased?: boolean,
    public mother_is_deceased?: boolean,
    public father_is_unknown?: boolean,
    public non_saudi_mother_as_provider?: boolean,
    public divorce_deed_number?: string,
    public divorce_deed_date?: string,
    public severely_handicapped?: boolean,
    public guardian_dob?: string,
    public life_status_father?: string,
    public life_status_mother?: string,
    public in_parent_dependent_list?: boolean
  ) {}
}
