import { Injectable } from '@angular/core';
import { Breadcrumb } from '@app/interfaces/breadcrumb.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);
  public breadcrumbs = this._breadcrumbs.asObservable();

  constructor() {}

  getCurrentBreadcrums() {
    return this._breadcrumbs.value;
  }

  setBreadcrumbs(urls: Breadcrumb[]) {
    this._breadcrumbs.next(urls);
  }

  // push to current breadcrumbs. Maynot work inside component constructor
  push(items: Breadcrumb | Breadcrumb[]) {
    const currentBreadcrumbs = this._breadcrumbs.getValue();
    if (Array.isArray(items)) {
      currentBreadcrumbs.push(...items);
    } else {
      currentBreadcrumbs.push(items);
    }
    this.setBreadcrumbs(currentBreadcrumbs);
  }

  // replace breadcrumb at index
  replace(breadcrumb: Breadcrumb, position: number) {
    const currentBreadcrumbs = this._breadcrumbs.getValue();
    try {
      currentBreadcrumbs[position] = breadcrumb;
      this.setBreadcrumbs(currentBreadcrumbs);
    } catch (err) {
      console.error(err);
    }
  }
}
