import { BuildingPermitStatus } from '@app/enums';

export class BuildingPermitLicense {
  relationships: any = {};

  constructor(
    public id?: number,
    public booking_id?: number,
    public license_number?: string,
    public status?: BuildingPermitStatus,
    public building_type_code?: string,
    public created_at?: Date
  ) {}
}
