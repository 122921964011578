export class Region {
  relationships = {};
  constructor(
    public id?: number,
    public name?: string, // name by selected language.
    public name_ar?: string,
    public name_en?: string,
    public lat?: number,
    public lon?: number,
    public lng?: number,
    public key?: string,
    public region_id?: number,
    public total?: number
  ) {}
}

export class RegionStatistic {
  relationships = {};

  constructor(public lat?: number, public lon?: number, public region_id?: number, public total?: number) {}
}
