import { ProjectBeneficiaryWaitingListStatus, ProjectTypes, UnitType } from '@app/enums';
import { Beneficiary } from './beneficiary';

export class ProjectBeneficiaryWaitingList {
  relationships: any = {
    beneficiary: { rel: 'has_one', model: Beneficiary },
  };
  beneficiary?: Beneficiary;

  constructor(
    public id?: number,
    public unit_type?: UnitType,
    public project_name?: string,
    public project_code?: string,
    public created_at?: Date,
    public registered_at?: Date,
    public booking_id?: number,
    public status?: ProjectBeneficiaryWaitingListStatus,
    public booking_fee?: number,
    public waiting_list_fee?: number,
    public waiting_expired_at?: Date,
    public sadad_invoice_number?: string,
    public azm_invoice_number?: string,
    public project_type?: ProjectTypes,
    public cancellation_at?: Date
  ) {}
}
