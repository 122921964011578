import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-recaptcha-v2-dialog',
  templateUrl: './recaptcha-v2-dialog.component.html',
  styleUrls: ['./recaptcha-v2-dialog.component.scss'],
})
export class RecaptchaV2DialogComponent implements OnInit {
  @Input() action!: string;
  @Input() identifier!: string;
  constructor(public modal: NgbActiveModal) {}

  ngOnInit() {}

  public onChangeCaptchaToken(token: string) {
    this.modal.close(token);
  }
}
