import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardLoadingSkeletonComponent } from './card-loading-skeleton.component';
import { LayoutDirectionModule } from '@app/shared/directives';

@NgModule({
  declarations: [CardLoadingSkeletonComponent],
  imports: [CommonModule, LayoutDirectionModule],
  exports: [CardLoadingSkeletonComponent],
})
export class CardLoadingSkeletonModule {}
