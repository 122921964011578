import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { currentLang } from '@app/helpers';
import { FilterInterface, ParamsInterface } from '@app/interfaces';
import { SakaniSession } from '@app/models';
import { NGX_LOADING_BAR_IGNORED } from '@ngx-loading-bar/http-client';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SakaniSessionService } from '../ui/sakani-session.service';
import { environment } from '@environment/environment';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(protected http: HttpClient, protected sakaniSessionService: SakaniSessionService) {}

  private static makeAuthHeaders(contentType: any): HttpHeaders {
    const authToken = sessionStorage.getItem('authentication');

    const headerOptions: any = {
      Accept: contentType,
      'Content-Type': contentType,
      'Accept-Language': currentLang(),
      'APP-Locale': currentLang(),
    };

    if (authToken) {
      headerOptions['authentication'] = authToken;
    }

    return new HttpHeaders(headerOptions);
  }

  private static parseUrlToItem(endpoint: string, id: string | number | null) {
    return id ? `${endpoint}/${id}` : endpoint;
  }

  apiGet(
    url: string,
    id: string | number = '',
    queryParams: {} = {},
    contentType = 'application/json',
    isPublicRequest = false,
    showLoadingaBar = true
  ): Observable<any> {
    let params = new HttpParams({
      fromObject: queryParams ? this.parseQueryParams(queryParams) : {},
    });

    params = this.appendFilterInOptToParams(params, queryParams);

    return this.http
      .get(ApiService.parseUrlToItem(url, id), {
        context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, !showLoadingaBar),
        params,
        headers: ApiService.makeAuthHeaders(contentType),
      })
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            this.onSessionInvalid();
          }
          return throwError(error);
        })
      );
  }

  apiGetIgnoreError(
    url: string,
    id: string | number = '',
    queryParams: {} = {},
    contentType = 'application/json',
    isPublicRequest = false
  ): Observable<any> {
    let params = new HttpParams({
      fromObject: queryParams ? this.parseQueryParams(queryParams) : {},
    });

    params = this.appendFilterInOptToParams(params, queryParams);

    return this.http
      .get(ApiService.parseUrlToItem(url, id), {
        params,
        headers: ApiService.makeAuthHeaders(contentType),
      })
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            this.onSessionInvalid();
          }
          return throwError(error);
        })
      );
  }

  apiPost<T>(
    url: string,
    data: object = {},
    id = '',
    contentType = 'application/json',
    isPublicRequest = false
  ): Observable<T> {
    data = Object.keys(data).length > 0 ? data : {};

    return this.http
      .post<T>(ApiService.parseUrlToItem(url, id), data, {
        headers: ApiService.makeAuthHeaders(contentType),
      })
      .pipe(
        catchError((error) => {
          if (error.status === 401) {
            this.onSessionInvalid();
          }
          return throwError(error);
        })
      );
  }

  apiPostEmptyResponse(
    url: string,
    id = '',
    data: any = {},
    contentType = 'application/json',
    isPublicRequest = false
  ): Observable<any> {
    data = Object.keys(data).length > 0 ? data : null;
    let queryParams;
    const includeParams = data && data['data'] && data['data']['include'];
    if (includeParams) {
      queryParams = { include: includeParams?.toString() };
    }
    return this.http
      .post(ApiService.parseUrlToItem(url, id), data, {
        headers: ApiService.makeAuthHeaders(contentType),
        responseType: 'text',
        params: queryParams,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          if (error.status === 401) {
            this.onSessionInvalid();
          }
          return throwError(error);
        })
      );
  }

  apiPut(
    url: string,
    id = '',
    data: object = {},
    contentType = 'application/json',
    isPublicRequest = false
  ): Observable<any> {
    data = Object.keys(data).length > 0 ? data : {};
    return this.http
      .put(ApiService.parseUrlToItem(url, id), data, {
        headers: ApiService.makeAuthHeaders(contentType),
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          if (error.status === 401) {
            this.onSessionInvalid();
          }
          return throwError(error);
        })
      );
  }

  apiPutWithFile(url: string, data: object = {}): Observable<any> {
    return this.http.put(url, data).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        if (error.status === 401) {
          this.onSessionInvalid();
        }
        return throwError(error);
      })
    );
  }

  apiDelete(url: string, id = '', queryParams: {} = {}, contentType = 'application/json', isPublicRequest = false) {
    const params = new HttpParams({
      fromObject: queryParams ? this.parseQueryParams(queryParams) : {},
    });

    return this.http
      .delete(ApiService.parseUrlToItem(url, id), {
        params,
        headers: ApiService.makeAuthHeaders(contentType),
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          if (error.status === 401) {
            this.onSessionInvalid();
          }
          return throwError(error);
        })
      );
  }

  apiUpload(apiEndpoint: string, data: any) {
    return this.http.post(apiEndpoint, data).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        if (error.status === 401) {
          this.onSessionInvalid();
        }
        return throwError({ error, data });
      })
    );
  }

  apiDownload(apiEndpoint: string, contentType: string): Observable<any> {
    const session = this.sakaniSessionService.currentSession;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': contentType,
      }),
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
    };

    return this.http.get<HttpResponse<Blob>>(apiEndpoint, options).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        if (error.status === 401) {
          this.onSessionInvalid();
        }
        return throwError({ error, data: {} });
      })
    );
  }

  protected parseQueryParams(params: ParamsInterface): {} {
    const queryParams: any = {};
    Object.keys(params).forEach((key) => {
      if (key === 'page') {
        Object.keys(params[key as keyof ParamsInterface]).forEach((attr) => {
          queryParams[`${key}[${attr}]`] = params[key as keyof ParamsInterface][attr];
        });
      } else if (key === 'sort') {
        if (Array.isArray(params[key])) {
          const sortArray = params[key];
          for (const item of sortArray as any[]) {
            queryParams[`${key}[${item.attr}]`] = item.opt;
          }
        } else {
          queryParams[key] = params[key];
          // queryParams[`${key}[${params[key].attr}]`] = params[key].opt;
        }
      } else if (key === 'filter') {
        /* handle the filters with opt == 'in' in appendFilterInOptToParams function. the query params
         * can not have multiple keys with same name but different values which is required to include all
         * the array items. For example, filter['code__in'][] = "value 1", filter['code__in'][] = "value 2"*/
        params[key as keyof ParamsInterface].forEach((item: any) => {
          const associates = item.associates ? '[' + item.associates.join('][') + ']' : '';
          let lhs = 'filter' + associates;
          lhs += item.opt ? `[${item.attr}__${item.opt}]` : `[${item.attr}]`;

          if (item.opt !== 'in' && !Array.isArray(item.value)) {
            queryParams[lhs] = item.value;
          }
        });
      } else if (key === 'include' && typeof queryParams[key] !== 'string') {
        queryParams[key] = params[key] ? (params[key] as string[]).join(',') : null;
      } else if (key === 'fields' && typeof queryParams[key] !== 'string') {
        Object.keys(params[key as keyof ParamsInterface]).forEach((attr) => {
          queryParams[`${key}[${attr}]`] = params[key as keyof ParamsInterface][attr];
        });
      } else {
        queryParams[key] = params[key as keyof ParamsInterface];
      }
    });
    return queryParams;
  }

  /* if the filter has 'in' opt, append the filter param separately as it is impossible to add
   * multiple keys with different values in the the object. */
  protected appendFilterInOptToParams(params: HttpParams, queryParams: any): HttpParams {
    if (queryParams && queryParams.filter && queryParams.filter.length > 0) {
      queryParams.filter.forEach((filter: any) => {
        if (filter.opt === 'in') {
          const associates = filter.associates ? '[' + filter.associates.join('][') + ']' : '';
          let lhs = 'filter' + associates;
          lhs += `[${filter.attr}__in][]`;
          params = params.append(`${lhs}`, `${filter.value}`);
        }
        if (Array.isArray(filter.value)) {
          filter.value.forEach((item: any) => {
            const lhs = `filter[${filter.attr}][]`;
            params = params.append(`${lhs}`, `${item}`);
          });
        }
      });
    }

    return params;
  }

  protected onSessionInvalid() {
    this.sakaniSessionService.clearSession();
  }

  public filterAttributes(currentFilter: any) {
    const filterAttributes: any[] = Object.keys(currentFilter)
      .map((key) => {
        if (currentFilter[key] !== undefined && currentFilter[key] !== null && currentFilter[key] !== '') {
          const filterAttribute: FilterInterface = {
            attr: key,
            value: !Array.isArray(currentFilter[key]) ? currentFilter[key] : (currentFilter[key] || []).join(','),
          };
          return filterAttribute;
        }
        return null;
      })
      .filter((el) => {
        return el != null;
      });

    return filterAttributes;
  }

  public sortAttributes(currentSort: any) {
    const sortAttributes: any[] = Object.keys(currentSort)
      .map((key) => {
        if (currentSort[key] !== undefined && currentSort[key] !== null && currentSort[key] !== '') {
          const sortAttribute = {
            attr: key,
            opt: currentSort[key],
          };
          return sortAttribute;
        }
        return null;
      })
      .filter((el) => {
        return el != null;
      });

    return sortAttributes;
  }
}
