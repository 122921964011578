import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, CONFIG } from '@angular/fire/compat/analytics';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastContainerModule } from '@app/shared/components/toast-container/toast-container.module';
import { SafeModule } from '@app/shared/pipes';

// environment
import { environment } from '@environment/environment';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig } from 'ngx-webstorage';
// components
import { RECAPTCHA_LANGUAGE, RECAPTCHA_V3_SITE_KEY, RecaptchaModule, RecaptchaV3Module } from 'ng-recaptcha-2';
import { SakaniErrorHandler } from './base/error/sakani-error-handle';
import { AuthGuard } from './guards/auth-guard';
import { ErrorSectionModule } from './shared/components/error-section/error-section.module';
import { SharedModule } from './shared/shared.module';

import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { BannerMessageModule } from './shared/components/banner-message/banner-message.module';
import { Constants } from '@app/helpers';
import { RecaptchaV2DialogModule } from './shared/dialogs/recaptcha-v2-dialog/recaptcha-v2-dialog.module';
import { NhcComponent } from './pages/nhc-page/nhc.component';
import { NhcRoutingModule } from './nhc-routing.module';
import { NhcTopbarComponent } from './pages/nhc-page/nhc-topbar/nhc-topbar.component';
import { provideEnvironmentNgxMask } from 'ngx-mask';

let currentLang: string = 'ar';
try {
  currentLang = localStorage.getItem('language') || 'ar';
} catch {}

@NgModule({
  declarations: [NhcComponent, NhcTopbarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NhcRoutingModule,
    RecaptchaModule,
    RecaptchaV3Module,
    SharedModule,
    BrowserAnimationsModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    TranslateModule.forRoot(),
    SafeModule,
    ToastContainerModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    ErrorSectionModule,
    BannerMessageModule,
    RecaptchaV2DialogModule,
  ],
  providers: [
    { provide: CONFIG, useValue: { send_page_view: false } },
    {
      provide: ErrorHandler,
      useClass: SakaniErrorHandler,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: Constants.recaptchaV3SiteKey,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: currentLang,
    },
    CookieService,
    AuthGuard,
    provideHttpClient(withInterceptorsFromDi()),
    provideEnvironmentNgxMask(),
    provideNgxWebstorage(
      withNgxWebstorageConfig({
        prefix: 'ng2-webstorage',
      }),
      withLocalStorage()
    ),
  ],
  bootstrap: [NhcComponent],
})
export class AppModule {}
