import { BookingRequestStatus, BookingRequestType } from '@app/enums';

export class BookingRequest {
  relationships: any = {};

  constructor(
    public booking_id?: number,
    public created_at?: string,
    public id?: number,
    public reject_reason?: string,
    public request_date?: string,
    public request_reason?: string,
    public request_type?: BookingRequestType,
    public reviewed_at?: string,
    public status?: BookingRequestStatus,
    public file?: string,
    public file_type?: string,
    public updated_at?: Date
  ) {}
}
