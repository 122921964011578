import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorSectionComponent } from './error-section.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ErrorSectionComponent],
  imports: [CommonModule, TranslateModule],
  exports: [ErrorSectionComponent],
})
export class ErrorSectionModule {}
