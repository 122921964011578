<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.className"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="remove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>

  <ng-template #text>
    <span class="initial-font" [innerHtml]="toast.textOrTpl"></span>
  </ng-template>
</ngb-toast>
