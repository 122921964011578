export const MARKET_UNIT_CONSTANTS = {
  minFootPrintForApartment: 50,
  minFootPrintForOtherUnitTypes: 150,
  maxFootPrintForApartment: 1000,
  maxFootPrintForOtherUnitTypes: 5000,
  minSizeForApartment: 50,
  minSizeForOtherUnitTypes: 150,
  maxSizeForApartment: 1000,
  maxSizeForOtherUnitTypes: 5000,
  defaultLocationLat: 24.3037495,
  defaultLocationLong: 45.1069021,
  statusApprove: 'complete',
  statusNeedUpdate: 'need_update',
  statusRejected: 'rejected',
  farzUnitComponentMapping: {
    'غرفة نوم': 'number_of_rooms',
    // eslint-disable-next-line quote-props
    مجلس: 'number_of_reception_rooms',
    // eslint-disable-next-line quote-props
    حمام: 'number_of_bathrooms',
    // eslint-disable-next-line quote-props
    صالة: 'number_of_living_rooms',
  },
  publishedActor: {
    owner: 'owner',
    broker: 'broker',
    company_owned: 'company_owned',
  },
};
