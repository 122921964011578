export class Amenity {
  relationships = {};
  constructor(
    public id?: string,
    public key?: string,
    public name?: string,
    public locale?: any,
    public quantity?: number
  ) {}
}
export class ProjectAmenity {
  relationships = {};
  constructor(public id?: number, public project_id?: number, public quantity?: number, public amenity_id?: number) {}
}
