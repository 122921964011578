export class RangeValueFilter {
  relationships = {};

  constructor(
    public max_rental_unit_price?: number,
    public max_unit_price?: number,
    public max_unit_size?: number,
    public min_rental_unit_price?: number,
    public min_unit_price?: number,
    public min_unit_size?: number,
    public min_auction_unit_size?: number,
    public max_auction_unit_size?: number
  ) {}
}
