import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MinMaxRange } from './min-max-range.pipe';

@NgModule({
  declarations: [MinMaxRange],
  imports: [CommonModule],
  exports: [MinMaxRange],
  providers: [DecimalPipe],
})
export class MinMaxRangeModule {}
