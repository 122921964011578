import { Injectable } from '@angular/core';
import { BeneficiaryApplicationStatus } from '@app/enums';
import { NavigationExtras, Router, ActivatedRoute } from '@angular/router';
import { Beneficiary, User } from '@app/models';
import { IamResponse } from '@app/models/iam-response';
import { Constants } from '@app/helpers';
import jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root',
})
export class AccessTokenService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}
  returnUrl = '';

  establishAndRedirect(beneficiary?: Beneficiary, isFirstLogin?: boolean, captchaToken?: string) {
    this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl') || 'marketplace';
    if (isFirstLogin) {
      const navigationExtras: NavigationExtras = {
        state: {
          beneficiary: JSON.stringify(beneficiary),
          recaptchaToken: captchaToken,
        },
        queryParams: { returnUrl: this.returnUrl },
      };
      this.router.navigate(['authentication', 'update-communication-channel'], navigationExtras);
    } else {
      this.returnUrl = this.activatedRoute.snapshot.queryParamMap.get('returnUrl') || 'marketplace';
      if (
        this.returnUrl.indexOf('disclaimer') > 0 ||
        this.returnUrl.indexOf('booking-summary') > 0 ||
        this.returnUrl.indexOf('success') > 0
      ) {
        this.router.navigate(['/marketplace']);
        return;
      }

      let platform = this.activatedRoute.snapshot.queryParamMap.get('platform');
      if (platform === 'v3') {
        location.href = this.returnUrl;
      } else {
        const external = this.activatedRoute.snapshot.queryParamMap.get(Constants.EXTERNAL);
        if (external) {
          const action = this.activatedRoute.snapshot.queryParamMap.get('action');
          const returnUrl = action ? `${this.returnUrl}?action=${action}` : this.returnUrl;
          window.location.href = returnUrl;
        } else {
          this.router.navigateByUrl(this.returnUrl);
        }
      }
    }
  }

  storeTokenData(authToken: string) {
    const jwtUser: any = jwt_decode(authToken);
    localStorage.setItem('jwtData', JSON.stringify(jwtUser));
    const acceptCookies = localStorage.getItem('accept_cookies');
    if (acceptCookies === 'false') {
      sessionStorage.setItem('authentication', authToken);
    }
  }

  createUser(beneficiary: Beneficiary, token?: string) {
    const user = new User();
    user.user_id = beneficiary.decrypted_national_id_number;
    user.first_name = beneficiary.first_name;
    user.last_name = beneficiary.last_name;
    user.name = beneficiary.name;
    user.role = 'beneficiary';
    user.profileType = 'citizen'; // temp solution to get V3 user profile link working in V3 main nav bar
    user.application_status = BeneficiaryApplicationStatus.STATUS_INITIAL;
    if (beneficiary.beneficiary_application) {
      user.application_status = beneficiary.beneficiary_application?.status;
    }
    user.active = beneficiary.active;
    user.national_id_number = beneficiary.national_id_number; // encrypted national id number
    user.title_ar = beneficiary.title_ar;
    user.title_en = beneficiary.title_en;
    user.last_login_date = beneficiary.last_login_date;
    user.preferred_products = beneficiary.preferred_products;
    user.preferred_unit_types = beneficiary.preferred_unit_types;
    user.preferred_city_id = beneficiary.preferred_city_id;
    user.preferred_region_id = beneficiary.preferred_region_id;
    user.viewed_marketplace_onboarding = beneficiary.viewed_marketplace_onboarding;
    user.email_address = beneficiary.email_address;

    if (token) {
      try {
        const jwtUser: any = jwt_decode(token);
        user.exp = jwtUser.exp * 1000;
      } catch {
        console.error('can not parse token to get data');
        user.exp = new Date().getTime() + 2 * 60 * 60 * 1000;
      }
    }

    return user;
  }

  createUserWithIam(beneficiary: Beneficiary, iamResponse: IamResponse, token?: string): User {
    const user = this.createUser(beneficiary, token);

    if (iamResponse) {
      user.iam_session_index = iamResponse.iamData.session_index;
      user.national_id_number = iamResponse.userRecord.national_id_number;
    }

    return user;
  }
}
