import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsListModule, SafeModule } from '@app/shared/pipes';
import { RadioModule } from '../radio/radio.module';
import { SharedModule } from '@app/shared/shared.module';
import { WarningBannerMessageComponent } from './warning-banner-message.component';

@NgModule({
  declarations: [WarningBannerMessageComponent],
  imports: [CommonModule, SharedModule, TranslateModule, ErrorsListModule, RadioModule, SafeModule],
  exports: [WarningBannerMessageComponent],
})
export class WarningBannerMessageModule {}
