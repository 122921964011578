import { JsonApi } from './json-api';
import { ImageMedia, TextualMedia, VideoMedia, BrochureMedia } from './media';

export class MediaObject {
  public banner: ImageMedia[] = [];
  public gallery: ImageMedia[] = [];
  public textual: TextualMedia[] = [];
  public video: VideoMedia[] = [];
  public pmediaObjectentation: any[] = [];
  public master_plan: ImageMedia[] = [];
  public logo: ImageMedia[] = [];
  public stamp: ImageMedia[] = [];
  public brochure: BrochureMedia[] = [];
  public deed_copy: BrochureMedia[] = [];
  public plan: BrochureMedia[] = [];
  public require_approval = true;
  public first_floor_plan: ImageMedia[] = [];
  public second_floor_plan: ImageMedia[] = [];
  public third_floor_plan: ImageMedia[] = [];
  public design_main: ImageMedia[] = [];
  public frontend: ImageMedia[] = [];
  public fixed_layout: BrochureMedia[] = [];
  public building_permit_document: BrochureMedia[] = [];
  public design_layout_document: BrochureMedia[] = [];
  public deed: BrochureMedia[] = [];
  public cadastral: BrochureMedia[] = [];
  public report: BrochureMedia[] = [];
  public inspection_copy: BrochureMedia[] = [];

  constructor(object: any) {
    this.build(object);
  }

  build(mediaObject: any) {
    if (mediaObject.banner && mediaObject.banner.id) {
      this.banner = [JsonApi.parseJsonApi(ImageMedia, mediaObject.banner)];
    }

    if (mediaObject.master_plan && mediaObject.master_plan.id) {
      this.master_plan = [JsonApi.parseJsonApi(ImageMedia, mediaObject.master_plan)];
    }

    if (mediaObject.textual && mediaObject.textual.id) {
      this.textual = [JsonApi.parseJsonApi(TextualMedia, mediaObject.textual)];
    }

    if (mediaObject.video && mediaObject.video.id) {
      this.video = [JsonApi.parseJsonApi(VideoMedia, mediaObject.video)];
    }

    if (mediaObject.logo && mediaObject.logo.id) {
      this.logo = [JsonApi.parseJsonApi(ImageMedia, mediaObject.logo)];
    }

    if (mediaObject.stamp && mediaObject.stamp.id) {
      this.stamp = [JsonApi.parseJsonApi(ImageMedia, mediaObject.stamp)];
    }

    if (mediaObject.brochure && mediaObject.brochure.id) {
      this.brochure = [JsonApi.parseJsonApi(BrochureMedia, mediaObject.brochure)];
    }

    if (mediaObject.deed_copy && mediaObject.deed_copy.id) {
      this.deed_copy = [JsonApi.parseJsonApi(BrochureMedia, mediaObject.deed_copy)];
    }

    if (mediaObject.plan && mediaObject.plan.id) {
      this.plan = [JsonApi.parseJsonApi(BrochureMedia, mediaObject.plan)];
    }

    if (mediaObject.pmediaObjectentation && mediaObject.pmediaObjectentation.id) {
      this.pmediaObjectentation = [JsonApi.parseJsonApi(ImageMedia, mediaObject.pmediaObjectentation)];
    }

    if (mediaObject.first_floor_plan && mediaObject.first_floor_plan.id) {
      this.first_floor_plan = [JsonApi.parseJsonApi(ImageMedia, mediaObject.first_floor_plan)];
    }

    if (mediaObject.second_floor_plan && mediaObject.second_floor_plan.id) {
      this.second_floor_plan = [JsonApi.parseJsonApi(ImageMedia, mediaObject.second_floor_plan)];
    }

    if (mediaObject.third_floor_plan && mediaObject.third_floor_plan.id) {
      this.third_floor_plan = [JsonApi.parseJsonApi(ImageMedia, mediaObject.third_floor_plan)];
    }

    if (mediaObject.design_main && mediaObject.design_main.id) {
      this.design_main = [JsonApi.parseJsonApi(ImageMedia, mediaObject.design_main)];
    }

    if (mediaObject.frontend && mediaObject.frontend.id) {
      this.frontend = [JsonApi.parseJsonApi(ImageMedia, mediaObject.frontend)];
    }

    if (mediaObject.fixed_layout && mediaObject.fixed_layout.id) {
      this.fixed_layout = [JsonApi.parseJsonApi(ImageMedia, mediaObject.fixed_layout)];
    }

    if (mediaObject.building_permit_document && mediaObject.building_permit_document.id) {
      this.building_permit_document = [JsonApi.parseJsonApi(BrochureMedia, mediaObject.building_permit_document)];
    }

    if (mediaObject.design_layout_document && mediaObject.design_layout_document.id) {
      this.design_layout_document = [JsonApi.parseJsonApi(BrochureMedia, mediaObject.design_layout_document)];
    }

    if (mediaObject.gallery) {
      this.gallery = mediaObject.gallery.map((item: any) => {
        return JsonApi.parseJsonApi(ImageMedia, item);
      });
    }

    this.require_approval = mediaObject.require_approve;

    if (mediaObject.deed && mediaObject.deed.id) {
      this.deed = [JsonApi.parseJsonApi(BrochureMedia, mediaObject.deed)];
    }

    if (mediaObject.cadastral && mediaObject.cadastral.id) {
      this.cadastral = [JsonApi.parseJsonApi(BrochureMedia, mediaObject.cadastral)];
    }

    if (mediaObject.report && mediaObject.report.id) {
      this.report = [JsonApi.parseJsonApi(BrochureMedia, mediaObject.report)];
    }

    if (mediaObject.inspection_copy && mediaObject.inspection_copy.id) {
      this.inspection_copy = [JsonApi.parseJsonApi(BrochureMedia, mediaObject.inspection_copy)];
    }
  }
}
