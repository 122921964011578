import { ImageSliderDataInterface, ImageThumbGalleryDataInterface } from '@app/shared/interfaces';
import { MarketUnitTextualMedia } from './market-unit-textual-media';
import { MARKET_UNIT_CONSTANTS } from '@app/helpers/market-unit-constants';
import { MarketUnitStatus } from '@app/enums/market-unit-status.enum';
import { MarketUnitTypes } from '@app/enums/market-unit-types.enum';
import { Region } from './region';
import { City } from './city';
import { FileData } from '@app/interfaces/file-data.interface';
import { DeedBorderInfo } from './deed';
import { RegaAdLicense } from './rega-ad-license';
import { MarketRequest } from './market-request';

const marketUnitConst: any = MARKET_UNIT_CONSTANTS;

export class MarketBorderInfo {
  constructor(
    public border_type?: string,
    public border_type_string?: string,
    public description?: string,
    public distance?: string,
    public distance_string?: string
  ) {}
}

export class MarketUnit {
  relationships = {
    borders: { rel: 'has_many', model: MarketBorderInfo },
    rega_ad_license: { rel: 'has_one', model: RegaAdLicense },
    base_request: { rel: 'has_one', model: MarketRequest },
  };

  constructor(
    public id?: number,
    public rega_ad_license?: RegaAdLicense,
    public beneficiary_national_id_number?: string,
    public city?: string,
    public city_name?: string,
    public deed_number?: string,
    public district?: string,
    public permit_id?: string,
    public region?: string,
    public region_name?: string,
    public size?: number,
    public deed_date?: string,
    public manually_unit_type?: string,
    public region_id?: number,
    public city_id?: number,
    public district_id?: number,
    public farz_data?: any,
    public status?: string,
    public unit_type?: string,
    public moj_service?: boolean,
    public farz_service?: boolean,
    public progress?: number,
    public owner_name?: string,
    public source?: string,
    public publish?: boolean,
    public reviewer_explanation?: string,
    public rejection_reason?: string,
    public publish_date?: Date,
    public textualMedia?: MarketUnitTextualMedia,
    public thumbnails?: any,
    public ad_number?: number,
    public latitude?: string,
    public longitude?: string,
    public number_of_rooms?: number,
    public unit_price?: number,
    public unit_number?: string,
    public plan_number?: string,
    public building_license_number?: string,
    public cellphone_number?: string,
    public number_of_floors?: number,
    public floor_number?: number,
    public building_footprint?: number,
    public number_of_reception_rooms?: number,
    public number_of_bathrooms?: number,
    public number_of_living_rooms?: number,
    public unit_age?: number,
    public unit_area?: number,
    public description?: string,
    public electricity?: boolean,
    public water?: boolean,
    public elevator?: boolean,
    public balcony?: boolean,
    public air_conditioning?: boolean,
    public kitchen?: boolean,
    public furnished?: boolean,
    public sewerage_system?: boolean,
    public garden?: boolean,
    public internal_stairs?: boolean,
    public independent_electricity?: boolean,
    public driver_room?: boolean,
    public basement?: boolean,
    public maid_room?: boolean,
    public parking?: boolean,
    public pool?: boolean,
    public broker_status?: string,
    public owner_cellphone_number?: string,
    public broker_name?: string,
    public broker_company_name?: string,
    public company_cr_number?: string,
    public preferred_communication_type?: {
      whatsapp: boolean;
      mobile_number: boolean;
    },
    public delegation_request_id?: string,
    public status_timeline?: any,
    public thumbnail_url?: string,
    public main_reception_room_area?: number,
    public main_living_room_area?: number,
    public main_bed_room_area?: number,
    public main_kitchen_area?: number,
    public main_street_width?: number,
    public main_building_face?: string,
    public valuer_unit_price?: number,
    public is_unit_reported?: boolean,
    public published_actor?: string,
    public land_type?: string,
    public borders?: MarketBorderInfo[],
    public region_obj?: Region,
    public city_obj?: City,
    public location?: any,
    public number_of_bedrooms?: number,
    public published_by?: string,
    public subsidizable?: boolean,
    public sort_value?: any,
    public marketplace_name?: string,
    public min_bed_room?: number,
    public max_bed_room?: number,
    public min_bathroom?: number,
    public max_bathroom?: number,
    public height?: number,
    public width?: number,
    public building_year?: number,
    public anonymity?: boolean,
    public amenities?: any,
    public group_unit_id?: number,
    public views_count?: number,
    public map_link?: string,
    public reason?: string,
    public reason_description?: string,
    public unit_visible?: boolean,
    public unpublish_reason?: string,
    public deed_hijri_date?: string,
    public complete?: string,
    public editable?: boolean,
    public interior_photos?: FileData[],
    public exterior_photos?: FileData[],
    public exterior_photo?: FileData,
    public distance_borders?: { [key in string]: DeedBorderInfo },
    public company_owned_cr_number?: string,
    public tags?: any[],
    public project_code?: string,
    public street?: string,
    public building_number?: string,
    public additional_number?: string,
    public property_utilities?: string,
    public northern_border?: string,
    public eastern_border?: string,
    public western_border?: string,
    public southern_border?: string,
    public ad_license_number?: string,
    public requester_name?: string,
    public base_request?: MarketRequest,
    public from_unit_marketing_request?: boolean
  ) {}

  get communication_by(): 'mobile_number' | 'whatsapp' | null | string {
    if (this?.preferred_communication_type?.mobile_number === true) return 'mobile_number';
    if (this?.preferred_communication_type?.whatsapp === true) return 'whatsapp';
    return null;
  }

  get bedRooms(): number | 0 {
    return this.number_of_bedrooms || this.number_of_rooms || this.min_bed_room || this.max_bed_room || 0;
  }
  get bathRooms(): number | 0 {
    return this.number_of_bathrooms || this.min_bathroom || this.max_bathroom || 0;
  }

  get farzObject() {
    const data = ((this.farz_data || {}).body || {}).data || {};
    const unitComponents = data.unit_components;
    if (unitComponents != null) {
      unitComponents.map((component: any) => {
        const componentEnKey = marketUnitConst.farzUnitComponentMapping[component.component_name];
        if (componentEnKey != null) {
          data[componentEnKey] = component.component_sum;
        }
      });
    }

    return data;
  }

  get detailUrl() {
    if (this.from_unit_marketing_request) {
      return `/market-units/${this.id}`;
    } else {
      return `/market-unit/${this.id}`;
    }
  }

  get imported(): boolean {
    return this.source === 'imported';
  }

  get isPublished() {
    return this.status === MarketUnitStatus.PUBLISHED;
  }

  get isApproved() {
    return this.status === MarketUnitStatus.APPROVED;
  }

  get isRejected() {
    return this.status === MarketUnitStatus.REJECTED;
  }

  get isLandSale() {
    return this.unit_type === MarketUnitTypes.LAND;
  }

  get canEditPublishedUnit() {
    if (this.from_unit_marketing_request) {
      return false;
    } else {
      return (this.isPublished || this.isApproved) && this.editable;
    }
  }

  get isUnderUpdate() {
    return (
      this.status === MarketUnitStatus.UNDER_UPDATE_BY_OWNER || this.status === MarketUnitStatus.UNDER_UPDATE_BY_BROKER
    );
  }

  get published_by_owner() {
    return this.published_actor === MARKET_UNIT_CONSTANTS.publishedActor.owner;
  }

  get lngLat() {
    const lat = +(this.location?.latitude || this.latitude);
    const lng = +(this.location?.longitude || this.longitude);
    const validLat = lat !== 0 && lat >= -90 && lat <= 90;
    const validLon = lng !== 0 && lng >= -90 && lng <= 90;
    if (validLat && validLon) {
      return [lng, lat];
    } else {
      return null;
    }
  }

  get imageGallery(): ImageSliderDataInterface[] {
    const marketImage: ImageSliderDataInterface = {
      sourceUrl: this.thumbnails?.resize_234x,
    };
    return [marketImage];
  }

  get showVisibilityUnit(): boolean {
    return this?.status !== 'approved';
  }

  get imageThumbsGallery(): ImageThumbGalleryDataInterface[] {
    const banner: ImageThumbGalleryDataInterface = {
      sourceUrl: this.exterior_photo?.file_url || '',
      thumbUrl: this.exterior_photo?.file_url || '',
      originUrl: this.exterior_photo?.file_url || '',
    };

    const gallery =
      this.interior_photos?.map((interior: FileData) => {
        const data: ImageThumbGalleryDataInterface = {
          sourceUrl: interior?.file_url || '',
          thumbUrl: interior?.file_url || '',
          originUrl: interior?.file_url || '',
        };
        return data;
      }) || [];

    return [banner, ...gallery].filter((image) => image.sourceUrl);
  }
}
