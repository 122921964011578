import { InvoiceStatus, PaymentChannel } from '@app/enums';

export class Invoice {
  relationships: any = {};

  constructor(
    public id?: number,
    public status?: InvoiceStatus,
    public beneficiary_national_id_number?: string,
    public payment_channel?: PaymentChannel,
    public invoice_number?: string,
    public order_number?: string,
    public order_type?: string,
    public sub_invoicer_id?: string,
    public created_at?: Date,
    public updated_at?: Date,
    public expiration_at?: Date,
    public paid_at?: string,
    public amount?: number,
    public snapshot?: any,
    public payment_snapshot?: any,
    public resource_id?: string,
    public resource_type?: string,
    public payment_reference_number?: string,
    public has_azm_invoice_report_file?: boolean,
    public has_azm_receipt_voucher_file?: boolean
  ) {}
}
