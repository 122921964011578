import { Injectable } from '@angular/core';
import { Endpoints } from '@app/helpers/endpoints';
import { ParamsInterface } from '@app/interfaces';
import { JsonApi, Project, ProjectUnit, UnitModel } from '@app/models';
import { ProjectWaitingListSetting } from '@app/models/project-waiting-list-setting';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { QueryPollingService } from './query-polling.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  mainIntermediaryProjectUrl!: string;
  mainIntermediaryUnitUrl!: string;
  queryProjectsUrl!: string;
  mainIntermediaryProjectV3Url!: string;

  mainIntermediaryUnitModelsUrl!: string;

  constructor(private api: ApiService) {
    this.mainIntermediaryProjectUrl = Endpoints.MAIN_INTERMEDIARY_V4 + '/projects';
    this.mainIntermediaryProjectV3Url = Endpoints.MAIN_INTERMEDIARY_V3 + '/projects';
    this.queryProjectsUrl = Endpoints.MARKETPLACE_PROJECTS_PATH;
    this.mainIntermediaryUnitUrl = Endpoints.MAIN_INTERMEDIARY_V4 + '/units';
    this.mainIntermediaryUnitModelsUrl = Endpoints.MAIN_INTERMEDIARY_V4 + '/unit_models';
  }

  getDetail(projectId: string): Observable<Project> {
    return this.api
      .apiGet(`${this.mainIntermediaryProjectUrl}/${projectId}`, undefined, {
        include: ['amenities', 'projects_amenities', 'developer', 'project_unit_types'],
      })
      .pipe(
        map((res: any) => {
          const project = JsonApi.parseJsonApi(Project, res.data, res.included);
          return project;
        })
      );
  }

  getUnits(projectId: string): Observable<ProjectUnit[]> {
    return this.api.apiGet(`${this.queryProjectsUrl}/${projectId}/available-units`).pipe(
      map((res: any) => {
        const projectUnits = res.data
          ? res.data.map((projectUnit: any) => JsonApi.parseJsonApi(ProjectUnit, projectUnit))
          : [];
        return projectUnits;
      })
    );
  }

  getQueueing(projectId: string): Observable<any> {
    return this.api
      .apiGet(
        Endpoints.QUERRY_POLLING_PATH,
        undefined,
        { topic: 'project_queueing', request_id: projectId },
        'application/json',
        false,
        false
      )
      .pipe(
        map((res) => {
          return res.data;
        })
      );
  }

  getAuctionUnits(projectId: string): Observable<ProjectUnit[]> {
    return this.api.apiGet(`${this.queryProjectsUrl}/${projectId}/available-units-for-auctions`).pipe(
      map((res: any) => {
        const projectUnits = res.data
          ? res.data.map((projectUnit: any) => JsonApi.parseJsonApi(ProjectUnit, projectUnit))
          : [];
        return projectUnits;
      })
    );
  }

  getUnitModels(projectId: string): Observable<UnitModel[]> {
    const params = {
      filter: [
        {
          attr: 'project_id',
          value: projectId,
        },
      ],
    };
    return this.api.apiGet(this.mainIntermediaryUnitModelsUrl, undefined, params).pipe(
      map((res) => {
        const unitModels = res.data.map((model: any) => JsonApi.parseJsonApi(UnitModel, model));
        return unitModels;
      })
    );
  }

  getUnitDetail(unitId: string | number, params: ParamsInterface = {}): Observable<ProjectUnit> {
    return this.api.apiGet(`${this.mainIntermediaryUnitUrl}/${unitId}`, undefined, params).pipe(
      map((res: any) => {
        const projectUnit = JsonApi.parseJsonApi(ProjectUnit, res.data, res.included);
        return projectUnit;
      })
    );
  }

  getWaitingListSetting(projectId?: number): Observable<ProjectWaitingListSetting | null> {
    return this.api.apiGet(`${this.mainIntermediaryProjectV3Url}/${projectId}/waiting_list_setting`).pipe(
      map((res: any) => {
        const wlSetting = res?.data ? JsonApi.parseJsonApi(ProjectWaitingListSetting, res.data) : null;
        return wlSetting;
      })
    );
  }

  getProjectsByProjectCodes(params: ParamsInterface): Observable<Project[]> {
    return this.api.apiGet(`${this.mainIntermediaryProjectUrl}`, undefined, params).pipe(
      map((res: any) => {
        const projects = res.data.map((model: any) => JsonApi.parseJsonApi(Project, model));
        return projects;
      })
    );
  }

  getUnitsApiV4(projectId: any) {
    return this.api.apiGet(`${this.mainIntermediaryProjectUrl}/${projectId}/available_units`).pipe(
      map((res) => {
        const projectUnits = res.data.map((projectUnit: any) => JsonApi.parseJsonApi(ProjectUnit, projectUnit));
        return projectUnits;
      })
    );
  }

  getProjectMedia(projectId: number | string | undefined) {
    return this.api.apiGet(`${Endpoints.MAIN_INTERMEDIARY_V4}/project_media?filter[project_id]=${projectId}`).pipe(
      map((res) => {
        const medias =
          res?.data?.map((item: any) => {
            const mapping: any = {};
            mapping.originUrl = item.attributes?.file_url;
            mapping.sourceUrl = item.attributes?.file_url;
            if (item.attributes?.file_type === 'video') {
              mapping.thumbUrl = null;
            }
            mapping.type = item.attributes?.file_type;
            return mapping;
          }) || [];
        return medias;
      })
    );
  }
}
