import { ConveyanceBeneficiaryStatus, ConveyanceStatus } from '@app/enums';
import { Booking } from './booking';

export class Conveyance {
  relationships: any = {};

  constructor(
    public id?: number,
    public booking_id?: number,
    public request_reason?: string,
    public reject_reason?: string,
    public request_type?: string,
    public status?: ConveyanceStatus, // this one has been used from admin side
    public moh_branch?: string,
    public moh_deed_document?: string,
    public deed_copy?: string,
    public conveyancing_status?: ConveyanceBeneficiaryStatus, // this one has been used from beneficiary side
    public request_date?: Date,
    public reviewed_at?: Date,
    public request_number?: string,
    public project_land_conveyance_id?: string,
    public deed_copy_id?: string,
    public conveyance_document_copy_id?: string,
    public moh_beneficiary_id_document?: string,
    public deed_number?: string,
    public deed_date?: string,
    public issued_deed_number?: string,
    public issued_deed_date?: string,
    public preview_report_and_building_photos?: string,
    public beneficiary_national_id_number?: string,
    public booking?: Booking,
    public other_reject_reason?: string,
    public created_at?: Date,
    public updated_at?: Date
  ) {}
}
