<ng-container *ngIf="captchaType === 'sakani_captcha'">
  <div class="captcha-wrapper" [class.invalid-captcha]="isInvalid || showErrors">
    <div class="recaptcha__image d-flex justify-content-center">
      <img src="{{ captchaImage?.img_data }}" />
    </div>

    <div class="sakani-input-group captcha-input hover-control">
      <input
        class="text-center form-control"
        type="text"
        [formControl]="captchaChallengeForm"
        [placeholder]="'AUTHENTICATION.CAPTCHA.CAPTCHA_PLACEHOLDER' | translate"
        appLayoutDirection
        mask="0000"
      />
      <div
        appLayoutDirection
        class="input-side-icon with-seperator d-flex align-items-center justify-content-center px-3"
      >
        <span
          (click)="getCaptchaImage()"
          class="svg-icon icon-reload svg-icon--large cursor-pointer"
        ></span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="captchaType === 'grecaptchaV2'">
  <div class="d-flex justify-content-center" [class.invalid-captcha]="isInvalid || showErrors">
    <re-captcha #captchaRef="reCaptcha" (resolved)="grecaptchaResolved($event)" [siteKey]="recaptchaV2SiteKey"></re-captcha>
  </div>
</ng-container>

<ng-container *ngIf="isInvalid">
<span
    class="w-100 text-danger d-inline-block text-break mt-2"
    *ngFor="let error of captchaChallengeForm | errorsList"
  >
    {{ error.message | uppercase | translate: { value: error.params } }}
  </span>
</ng-container>

<ng-container *ngIf="showErrors">
  <span
    class="w-100 text-danger d-inline-block text-break mt-2 text-center"
  >
    {{ 'ERRORS.RECAPTCHA_VERIFICATION_FAILED' | translate }}
  </span>
</ng-container>