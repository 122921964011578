import { User } from '@app/models';

export class SakaniSession {
  constructor(public user?: User) {}

  get isAuthenticated(): boolean {
    if (!this.user) {
      return false;
    }
    if (!this.user.user_id) {
      return false;
    }
    return true;
  }
}
