export class RegisterInterestMegaProject {
  relationships: any = {};

  constructor(
    public id?: string,
    public mega_project_id?: string,
    public email?: string,
    public project_codes?: string[],
    public extra_information?: {
      mega_project_name?: string;
    }
  ) {}
}

export class RegisterInterestProject {
  relationships: any = {};

  constructor(
    public id?: string,
    public project_code?: string,
    public extra_info?: {
      name?: string;
      email?: string;
    }
  ) {}
}

export class RegisterInterestMarketplaceDay {
  relationships: any = {};

  constructor(public id?: string, public beneficiary_national_id_number?: string) {}
}
