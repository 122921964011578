import { ConstructionCertStatus } from '@app/enums';

export class ConstructionCert {
  relationships: any = {};

  constructor(
    public booking_id?: number,
    public file?: string,
    public status?: ConstructionCertStatus,
    public reject_reason?: string,
    public reviewed_at?: Date,
    public created_at?: Date,
    public updated_at?: Date
  ) {}
}
